import React, { useState } from 'react';
import { cn } from '../../utils';

export function Collapsible({ className = "", open = false, onOpenChange, children, ...props }) {
  const [isOpen, setIsOpen] = useState(open);

  const handleOpenChange = (newOpen) => {
    setIsOpen(newOpen);
    if (onOpenChange) {
      onOpenChange(newOpen);
    }
  };

  return (
    <div 
      className={cn("", className)} 
      data-state={isOpen ? "open" : "closed"}
      {...props}
    >
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            open: isOpen,
            onOpenChange: handleOpenChange
          });
        }
        return child;
      })}
    </div>
  );
}

export function CollapsibleTrigger({ className = "", children, onOpenChange, open, ...props }) {
  return (
    <button
      type="button"
      onClick={() => onOpenChange && onOpenChange(!open)}
      className={cn(
        "flex w-full items-center justify-between py-2",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export function CollapsibleContent({ 
  className = "", 
  open = false,
  children,
  ...props 
}) {
  return (
    <div
      className={cn(
        "overflow-hidden transition-all duration-300",
        open ? "max-h-screen opacity-100" : "max-h-0 opacity-0",
        className
      )}
      {...props}
    >
      <div className={cn("py-2")}>
        {children}
      </div>
    </div>
  );
}