import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, Plus, Save, Trash2, Calendar } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Switch } from "../../ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Input } from "../../ui/input";
import { cn } from "../../../utils";

const US_HOLIDAYS = [
  { id: 'new-years-eve', name: "New Year's Eve", date: '12-31' },
  { id: 'new-years', name: "New Year's Day", date: '01-01' },
  { id: 'memorial-day', name: "Memorial Day", date: '05-30' },
  { id: 'independence-day', name: "4th of July", date: '07-04' },
  { id: 'labor-day', name: "Labor Day", date: '09-04' },
  { id: 'thanksgiving', name: "Thanksgiving", date: '11-23' },
  { id: 'christmas-eve', name: "Christmas Eve", date: '12-24' },
  { id: 'christmas', name: "Christmas Day", date: '12-25' }
];

const formatTimeDisplay = (time24) => {
  if (!time24) return '';
  try {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).toLowerCase();
  } catch (error) {
    return time24;
  }
};

const formatDate = (dateStr) => {
  const [month, day] = dateStr.split('-');
  const date = new Date(2000, parseInt(month) - 1, parseInt(day));
  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
};

const sortHolidaysByDate = (holidays, customHolidays = []) => {
    const allHolidays = [
      ...holidays.map(h => ({ ...h, isCustom: false })),
      ...customHolidays.map(h => ({ ...h, isCustom: true }))
    ];
    
    return allHolidays.sort((a, b) => {
      const [aMonth, aDay] = (a.isCustom ? a.date : a.date).split('-').map(Number);
      const [bMonth, bDay] = (b.isCustom ? b.date : b.date).split('-').map(Number);
      
      if (aMonth !== bMonth) return aMonth - bMonth;
      return aDay - bDay;
    });
  };


const TIME_OPTIONS = Array.from({ length: 48 }).map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;
  return {
    value: time24,
    label: formatTimeDisplay(time24)
  };
});

export default function HolidayHoursModal({ isOpen, onClose, holidayHours, onUpdateHolidayHours }) {
  const [customDate, setCustomDate] = useState('');
  const [customName, setCustomName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [originalHours, setOriginalHours] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setOriginalHours(holidayHours);
    setHasChanges(JSON.stringify(holidayHours) !== JSON.stringify(originalHours));
  }, [holidayHours]);
  
  const sortedHolidays = sortHolidaysByDate(US_HOLIDAYS, Object.values(holidayHours).filter(h => h.isCustom));

  const handleHolidayUpdate = (holidayId, updates) => {
    const updatedHours = {
      ...holidayHours,
      [holidayId]: {
        ...holidayHours[holidayId],
        ...updates
      }
    };
    onUpdateHolidayHours(updatedHours);
  };

  const handleAddCustomDate = () => {
    if (!customDate || !customName) return;
    
    // Convert YYYY-MM-DD to MM-DD format and handle timezone offset
    const date = new Date(2024, parseInt(customDate.split('-')[0]) -1, parseInt(customDate.split('-')[1])); // Added year for proper date object creation.
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = (date.getDate()).toString().padStart(2, '0');
    const formattedDate = `${month}-${day}`;
    
    const holidayId = `custom-${Date.now()}`;
    const updatedHours = {
      ...holidayHours,
      [holidayId]: {
        name: customName,
        date: formattedDate,
        isOpen: false,
        type: 'specific',
        timeRanges: [{ start: '09:00', end: '17:00' }]
      }
    };
    onUpdateHolidayHours(updatedHours);
    setCustomDate('');
    setCustomName('');
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onUpdateHolidayHours(holidayHours);
      onClose();
    } catch (error) {
      console.error('Error saving holiday hours:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const renderHolidayRow = (holiday) => {
    const settings = holidayHours[holiday.id] || {
      isOpen: false,
      type: 'specific',
      timeRanges: [{ start: '09:00', end: '17:00' }]
    };
    const isOpen = settings.isOpen;
    const isAllDay = settings.type === 'allDay';
    const timeRange = settings.timeRanges?.[0] || { start: '09:00', end: '17:00' };

    return (
      <div key={holiday.id} className="p-4 border-b border-gray-200 last:border-0">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h3 className="text-sm font-medium text-gray-900">{holiday.name}</h3>
            <p className="text-sm text-gray-500">{formatDate(holiday.date)}</p>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={() => handleHolidayUpdate(holiday.id, {
                isOpen: !isOpen,
                type: !isOpen ? 'specific' : undefined,
                timeRanges: !isOpen ? [{ start: '09:00', end: '17:00' }] : undefined
              })}
              className={cn(
                "px-3 py-1.5 text-sm font-medium rounded-md transition-colors",
                isOpen 
                  ? "bg-gray-900 text-white hover:bg-gray-800" 
                  : "bg-gray-100 text-gray-500 hover:bg-gray-200"
              )}
            >
              {isOpen ? 'Open' : 'Closed'}
            </button>

            {isOpen && (
              <button
                onClick={() => handleHolidayUpdate(holiday.id, {
                  type: isAllDay ? 'specific' : 'allDay',
                  timeRanges: isAllDay ? [{ start: '09:00', end: '17:00' }] : undefined
                })}
                className={cn(
                  "inline-flex items-center gap-2 px-3 py-1.5 rounded-md transition-colors",
                  isAllDay 
                    ? "bg-gray-900 text-white hover:bg-gray-800" 
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                )}
              >
                <Clock className="w-4 h-4" />
                {isAllDay ? 'All Day' : 'Custom'}
              </button>
            )}
          </div>
        </div>

        {isOpen && !isAllDay && (
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="text-sm text-gray-600">Opening Time</label>
              <Select
                value={timeRange.start}
                onValueChange={(value) => handleHolidayUpdate(holiday.id, {
                  timeRanges: [{ ...timeRange, start: value }]
                })}
              >
                <SelectTrigger>
                  <SelectValue>{(value) => formatTimeDisplay(value)}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {TIME_OPTIONS.map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label className="text-sm text-gray-600">Closing Time</label>
              <Select
                value={timeRange.end}
                onValueChange={(value) => handleHolidayUpdate(holiday.id, {
                  timeRanges: [{ ...timeRange, end: value }]
                })}
              >
                <SelectTrigger>
                  <SelectValue>{(value) => formatTimeDisplay(value)}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {TIME_OPTIONS.map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose} className="max-w-3xl">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Holiday Hours</DialogTitle>
        </DialogHeader>
        
        <div className="max-h-[60vh] overflow-y-auto">
          {sortedHolidays.map(renderHolidayRow)}
          
          {Object.entries(holidayHours)
            .filter(([id]) => id.startsWith('custom-'))
            .map(([id, holiday]) => {
              const customHoliday = {
                id,
                name: holiday.name,
                date: holiday.date,
                isCustom: true
              };
              return (
                <div key={id} className="group relative">
                  {renderHolidayRow(customHoliday)}
                  <button
                    onClick={() => {
                      const newHours = { ...holidayHours };
                      delete newHours[id];
                      onUpdateHolidayHours(newHours);
                    }}
                    className="absolute right-4 top-4 p-1.5 opacity-0 group-hover:opacity-100 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full transition-all"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              );
            })}
        </div>

        <div className="border-t pt-4 mt-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-gray-900">Add Custom Date</h3>
            <motion.button
              onClick={handleAddCustomDate}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={!customDate || !customName}
              className={cn(
                "flex items-center gap-2 px-3 py-1.5 rounded-md transition-colors text-sm",
                !customDate || !customName
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-gray-900 text-white hover:bg-gray-800"
              )}
            >
              <Plus className="w-4 h-4" />
              Add Date
            </motion.button>
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            className="grid grid-cols-2 gap-4 mb-4"
          >
            <div>
            <div className="grid grid-cols-2 gap-2">
              <Select
                value={customDate.split('-')[0] || ''}
                onValueChange={(month) => {
                  const day = customDate.split('-')[1] || '01';
                  setCustomDate(`${month}-${day}`);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Month">
                    {(value) => {
                      if (!value) return "Month";
                      const date = new Date(2000, parseInt(value) - 1);
                      return date.toLocaleString('default', { month: 'long' });
                    }}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {Array.from({length: 12}, (_, i) => {
                    const month = (i + 1).toString().padStart(2, '0');
                    const date = new Date(2000, i);
                    return (
                      <SelectItem key={month} value={month}>
                        {date.toLocaleString('default', { month: 'long' })}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>

              <Select
                value={customDate.split('-')[1] || ''}
                onValueChange={(day) => {
                  const month = customDate.split('-')[0] || '01';
                  setCustomDate(`${month}-${day}`);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Day">
                    {(value) => value || "Day"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {Array.from({length: 31}, (_, i) => {
                    const day = (i + 1).toString().padStart(2, '0');
                    return (
                      <SelectItem key={day} value={day}>
                        {day}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
            </div>
            <div>
              <Input
                type="text"
                placeholder="Holiday name..."
                value={customName}
                onChange={(e) => setCustomName(e.target.value)}
                className="w-full bg-white border border-gray-200 focus:ring-1 focus:ring-gray-200"
              />
            </div>
          </motion.div>

          <div className="flex justify-end">
            <motion.button
              onClick={handleSave}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={isSaving || !hasChanges}
              className={cn(
                "flex items-center gap-2 px-4 py-2 rounded-md transition-colors",
                isSaving || !hasChanges
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-gray-900 text-white hover:bg-gray-800"
              )}
            >
              <Save className="w-4 h-4" />
              Save Changes
            </motion.button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}