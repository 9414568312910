import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleMap, Polygon } from '@react-google-maps/api';
import { 
  ZONE_COLORS,
  ZONE_BG_COLORS,
  COLOR_PALETTE, 
  DEFAULT_COLOR,
  DEFAULT_BG_COLOR,
  getBgColor,
  getZoneColor
} from '../../../utils/zoneColors';

const defaultCenter = {
  lat: 40.7128,
  lng: -74.0060
};


const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "off" }]
    }
  ]
};

const ZipcodeCoverageMap = ({
  zipcodes = [],
  zones = [],
  className = "",
  zipBoundariesClient
}) => {
  const [map, setMap] = useState(null);
  const [boundaries, setBoundaries] = useState([]);
  const zoneColorsRef = useRef({});
  const colorIndexRef = useRef(0);

  // Initial verification of client
  useEffect(() => {
    if (!zipBoundariesClient) {
      console.error('zipBoundariesClient is not initialized');
    }
  }, [zipBoundariesClient]);

  // Database check
  useEffect(() => {
    const checkDatabase = async () => {
      if (!zipBoundariesClient) {
        console.error('zipBoundariesClient is not initialized');
        return;
      }

      try {
        const { data, error } = await zipBoundariesClient
          .from('zip_boundaries')
          .select('count')
          .limit(1);

        if (error) {
          console.error('Database connection error:', error);
          return;
        }
      } catch (err) {
        console.error('Database connection error:', err);
      }
    };

    checkDatabase();
  }, [zipBoundariesClient]);

  // Helper: Assign a color to a zoneId
  const getColorForZoneId = useCallback((zoneId) => {
    return getZoneColor(zoneId);  // Use the shared function
  }, []);

  // Fetch boundaries
  useEffect(() => {
    const fetchBoundaries = async () => {
      if (!zipBoundariesClient) {
        console.error('zipBoundariesClient is not initialized');
        return;
      }

      try {
        if (!zipcodes || zipcodes.length === 0) {
          setBoundaries([]);
          return;
        }

        // Ensure zipcodes are strings and unique
        const uniqueZipcodes = [...new Set(zipcodes.map(zip => zip.toString()))];
        const { data, error } = await zipBoundariesClient
          .from('zip_boundaries')
          .select('*')
          .in('zipcode', uniqueZipcodes);

        if (error) {
          console.error('Error fetching boundaries:', error);
          throw error;
        }

        if (!data || data.length === 0) {
          setBoundaries([]);
          return;
        }

        const formattedBoundaries = data.map(boundary => {
          if (!boundary.coordinates || !Array.isArray(boundary.coordinates)) {
            return null;
          }

          return {
            zipcode: boundary.zipcode,
            paths: boundary.coordinates
              .map(coord => {
                if (!Array.isArray(coord) || coord.length < 2) {
                  return null;
                }
                return {
                  lat: coord[1],
                  lng: coord[0]
                };
              })
              .filter(Boolean)
          };
        })
        .filter(boundary => boundary && boundary.paths.length > 0);

        setBoundaries(formattedBoundaries);

        // Auto-center map on boundaries
        if (formattedBoundaries.length > 0 && map) {
          const bounds = new window.google.maps.LatLngBounds();
          formattedBoundaries.forEach(boundary => {
            boundary.paths.forEach(coord => {
              bounds.extend(coord);
            });
          });
          map.fitBounds(bounds);
        }
      } catch (err) {
        console.error('Error in fetchBoundaries:', err);
      }
    };

    fetchBoundaries();
  }, [zipcodes, map, zipBoundariesClient]);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Get zone for each zipcode
  const getZipcodeZoneId = useCallback((zipcode) => {
    const zone = zones.find(z => (z.zipcodes || []).includes(zipcode));
    return zone?.id || null;
  }, [zones]);

  return (
    <div className={`w-full h-full ${className}`}>
      <div className="w-full h-full rounded-lg overflow-hidden border border-gray-200">
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={defaultCenter}
          zoom={11}
          options={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {boundaries.map((boundary) => {
            const zoneId = getZipcodeZoneId(boundary.zipcode);
            const color = getColorForZoneId(zoneId);

            return (
              <Polygon
                key={boundary.zipcode}
                paths={boundary.paths}
                options={{
                  fillColor: color,
                  fillOpacity: 0.25,
                  strokeColor: color,
                  strokeWeight: 2
                }}
              />
            );
          })}
        </GoogleMap>
      </div>
    </div>
  );
};

export default ZipcodeCoverageMap;