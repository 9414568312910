import React from 'react';
import { cn } from '../../utils';

export function Alert({ variant = "default", className = "", children, ...props }) {
  const variants = {
    default: "bg-gray-50 border-gray-200 text-gray-900",
    destructive: "bg-red-50 border-red-200 text-red-900",
    success: "bg-green-50 border-green-200 text-green-900"
  };

  return (
    <div
      role="alert"
      className={cn(
        "relative rounded-lg border p-4",
        variants[variant],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function AlertTitle({ className = "", children, ...props }) {
  return (
    <h5 
      className={cn("font-medium leading-none tracking-tight", className)}
      {...props}
    >
      {children}
    </h5>
  );
}

export function AlertDescription({ className = "", children, ...props }) {
  return (
    <div
      className={cn("mt-1 text-sm [&_p]:leading-relaxed", className)}
      {...props}
    >
      {children}
    </div>
  );
}