// src/config/zoneColors.js

/**
 * Shared color configuration for zone-related components
 */

// Primary zone colors
export const ZONE_COLORS = {
  default: '#3B82F6',    // Info Blue (Default)
  1: '#111827',         // Gray 900
  2: '#DC2626',         // Red 600
  3: '#22C55E',         // Success Green
  4: '#EAB308',         // Warning Yellow
  5: '#7C3AED',         // Violet 600
  6: '#059669',         // Emerald 600
  7: '#DB2777',         // Pink 600
  9: '#2563EB',         // Blue 600
  8: '#EA580C',         // Orange 600
  10: '#9333EA',        // Purple 600
  11: '#65A30D',        // Lime 600
  12: '#0891B2',        // Cyan 600
  13: '#B91C1C',        // Red 700
  14: '#0D9488',        // Teal 600
  15: '#C026D3',        // Fuchsia 600
  16: '#15803D',        // Green 700
  17: '#D97706',        // Amber 600
  18: '#374151',        // Gray 700
  19: '#4F46E5',        // Indigo 600
  20: '#EF4444',        // Red 500
  21: '#0369A1',        // Sky 600
  22: '#1D4ED8',        // Blue 700
  23: '#0E7490'         // Cyan 700
};

// Background colors with opacity
export const ZONE_BG_COLORS = {
  default: 'rgba(59, 130, 246, 0.2)',   // Info Blue bg
  1: 'rgba(17, 24, 39, 0.2)',           // Gray 900 bg
  2: 'rgba(220, 38, 38, 0.2)',          // Red 600 bg
  3: 'rgba(34, 197, 94, 0.2)',          // Success Green bg
  4: 'rgba(234, 179, 8, 0.2)',          // Warning Yellow bg
  5: 'rgba(124, 58, 237, 0.2)',         // Violet 600 bg
  6: 'rgba(5, 150, 105, 0.2)',          // Emerald 600 bg
  7: 'rgba(219, 39, 119, 0.2)',         // Pink 600 bg
  9: 'rgba(37, 99, 235, 0.2)',          // Blue 600 bg
  8: 'rgba(234, 88, 12, 0.2)',          // Orange 600 bg
  10: 'rgba(147, 51, 234, 0.2)',        // Purple 600 bg
  11: 'rgba(101, 163, 13, 0.2)',        // Lime 600 bg
  12: 'rgba(8, 145, 178, 0.2)',         // Cyan 600 bg
  13: 'rgba(185, 28, 28, 0.2)',         // Red 700 bg
  14: 'rgba(13, 148, 136, 0.2)',        // Teal 600 bg
  15: 'rgba(192, 38, 211, 0.2)',        // Fuchsia 600 bg
  16: 'rgba(21, 128, 61, 0.2)',         // Green 700 bg
  17: 'rgba(217, 119, 6, 0.2)',         // Amber 600 bg
  18: 'rgba(55, 65, 81, 0.2)',          // Gray 700 bg
  19: 'rgba(79, 70, 229, 0.2)',         // Indigo 600 bg
  20: 'rgba(239, 68, 68, 0.2)',         // Red 500 bg
  21: 'rgba(3, 105, 161, 0.2)',         // Sky 600 bg
  22: 'rgba(29, 78, 216, 0.2)',         // Blue 700 bg
  23: 'rgba(14, 116, 144, 0.2)'         // Cyan 700 bg
};

// Ordered array of colors for sequential assignment
export const COLOR_PALETTE = [
  ZONE_COLORS.default,     // Info Blue (Default)
  ZONE_COLORS[1],          // Gray 900
  ZONE_COLORS[2],          // Red 600
  ZONE_COLORS[3],          // Success Green
  ZONE_COLORS[4],          // Warning Yellow
  ZONE_COLORS[5],          // Violet 600
  ZONE_COLORS[6],          // Emerald 600
  ZONE_COLORS[7],          // Pink 600
  ZONE_COLORS[8],          // Blue 600
  ZONE_COLORS[9],          // Orange 600
  ZONE_COLORS[10],         // Purple 600
  ZONE_COLORS[11],         // Lime 600
  ZONE_COLORS[12],         // Cyan 600
  ZONE_COLORS[13],         // Red 700
  ZONE_COLORS[14],         // Teal 600
  ZONE_COLORS[15],         // Fuchsia 600
  ZONE_COLORS[16],         // Green 700
  ZONE_COLORS[17],         // Amber 600
  ZONE_COLORS[18],         // Gray 700
  ZONE_COLORS[19],         // Indigo 600
  ZONE_COLORS[20],         // Red 500
  ZONE_COLORS[21],         // Sky 600
  ZONE_COLORS[22],         // Blue 700
  ZONE_COLORS[23]          // Cyan 700
];

// Helper function to generate background colors with opacity
export const getBgColor = (color, opacity = 0.2) => {
  // Convert hex to rgb
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Default color for non-zoned items
export const DEFAULT_COLOR = ZONE_COLORS.default;
export const DEFAULT_BG_COLOR = ZONE_BG_COLORS.default;

// Add this function to the exports
export const getZoneColor = (zoneId) => {
  if (!zoneId || zoneId === 'default') {
    return ZONE_COLORS.default;
  }

  // Convert to number if it's a string
  const numericId = typeof zoneId === 'string' ? parseInt(zoneId, 10) : zoneId;

  // Important: Check specifically for zone ID existence
  if (numericId && ZONE_COLORS[numericId]) {
    return ZONE_COLORS[numericId];  // This returns the exact color from the mapping
  }

  return DEFAULT_COLOR;
};