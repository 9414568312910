import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Check } from 'lucide-react';
import { cn } from '../../utils';

const menuVariants = {
  hidden: { 
    opacity: 0,
    y: -4,
    scale: 0.95,
    transition: {
      duration: 0.2,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0,
    x: -4
  },
  visible: { 
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const SelectContext = React.createContext({
  value: undefined,
  onValueChange: () => {},
  isOpen: false,
  setIsOpen: () => {}
});

export function Select({ value, onValueChange, children, disabled = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <SelectContext.Provider value={{ value, onValueChange, isOpen, setIsOpen, disabled }}>
      <div className="relative" ref={ref}>
        {children}
      </div>
    </SelectContext.Provider>
  );
}

export function SelectTrigger({ children, className }) {
  const { isOpen, setIsOpen, disabled } = React.useContext(SelectContext);

  return (
    <motion.button
      type="button"
      onClick={() => !disabled && setIsOpen(!isOpen)}
      whileTap={!disabled ? { scale: 0.98 } : undefined}
      className={cn(
        "w-full flex items-center justify-between px-3 py-2 rounded-lg border bg-white transition-colors duration-200",
        isOpen ? "border-gray-900 ring-1 ring-gray-900" : "border-gray-200",
        disabled ? "opacity-50 cursor-not-allowed bg-gray-50" : "hover:border-gray-300",
        className
      )}
    >
      {children}
      <motion.div
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
      >
        <ChevronDown className="w-4 h-4 text-gray-500" />
      </motion.div>
    </motion.button>
  );
}

export function SelectValue({ children, placeholder }) {
  const { value } = React.useContext(SelectContext);

  // If children is provided as a function, call it with the current value
  const content = typeof children === 'function' ? children(value) : children;

  return (
    <span className={cn(
      "block truncate",
      value ? "text-gray-900" : "text-gray-500"
    )}>
      {content || placeholder || value}
    </span>
  );
}

export function SelectContent({ children, className }) {
  const { isOpen } = React.useContext(SelectContext);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={menuVariants}
          className={cn(
            "absolute z-50 w-full mt-1 py-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto overflow-x-hidden",
            className
          )}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function SelectItem({ value: itemValue, children, className }) {
  const { value, onValueChange, setIsOpen } = React.useContext(SelectContext);
  const isSelected = itemValue === value;

  return (
    <motion.button
      type="button"
      onClick={() => {
        onValueChange?.(itemValue);
        setIsOpen(false);
      }}
      className={cn(
        "w-full flex items-center justify-between px-3 py-2 text-left text-gray-900 hover:bg-gray-50 transition-colors duration-200",
        className
      )}
      whileHover={{ x: 2 }}
      whileTap={{ scale: 0.98 }}
      variants={itemVariants}
    >
      <span className="truncate block">{children}</span>
      {isSelected && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
        >
          <Check className="w-4 h-4 text-gray-900" />
        </motion.div>
      )}
    </motion.button>
  );
}