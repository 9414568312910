// src/components/signup/OnboardingRoutes.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { getSupabaseClient } from '../../supabaseClient';

export function getOnboardingRedirectPath(current_step) {
  switch (current_step) {
    case 'not_started':
      return '/onboarding/intro';
    case 'intro':
      return '/onboarding/intro';
    case 'business_locations_details':
      return '/onboarding/business';
    case 'business_details':
      return '/onboarding/business';
    case 'business_workflows':
      return '/onboarding/workflows';
    case 'notifications':  // Add this case
      return '/onboarding/notifications';
    case 'integration': 
        return '/onboarding/integration';  // New step
    case 'payment': 
        return '/onboarding/payment'; 
    case 'complete':
      return '/home';
    default:
      return '/onboarding/intro';
  }
}

const OnboardingRoute = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const supabase = getSupabaseClient('laundromat');

  useEffect(() => {
    let isMounted = true;

    const checkOnboardingStatus = async () => {
      try {
        console.log('OnboardingRoutes: Checking status for path:', location.pathname);

        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          navigate('/login');
          return;
        }

        const { data: onboarding, error } = await supabase
          .from('onboarding_data')
          .select('current_step')
          .eq('account_id', session.user.id)
          .single();

        if (!isMounted) return;

        if (error && error.code === '42P01') {
          setLoading(false);
          return;
        }

        if (error) throw error;

        console.log('OnboardingRoutes: Current step:', onboarding?.current_step);

        // Check if this is their first login
        if (onboarding?.current_step === 'not_started') {
          const { error: updateError } = await supabase
            .from('onboarding_data')
            .update({ current_step: 'intro' })
            .eq('account_id', session.user.id);

          if (updateError) throw updateError;

          navigate('/onboarding/intro');
          setLoading(false);
          return;
        }

        const correctPath = getOnboardingRedirectPath(onboarding?.current_step);
        console.log('OnboardingRoutes: Correct path:', correctPath);

        if (location.pathname !== correctPath) {
          console.log('OnboardingRoutes: Navigating to correct path');
          navigate(correctPath);
        }

        setLoading(false);
      } catch (error) {
        console.error('OnboardingRoutes error:', error);
        if (isMounted) setLoading(false);
      }
    };

    checkOnboardingStatus();

    return () => {
      isMounted = false;
    };
  }, [navigate, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
};

export default OnboardingRoute;