import React from 'react';
import { X } from 'lucide-react';
import { cn } from '../../utils';

export function Dialog({ open, onOpenChange, children, className }) {
  if (!open) return null;
  return (
    <div className="fixed inset-0 z-[10000] grid place-items-center overflow-y-auto p-4 sm:p-6">
      <div 
        className="fixed inset-0 bg-black/50" 
        onClick={() => onOpenChange?.(false)}
      />
      <div className="min-h-[var(--min-height)] flex justify-center items-center w-full">
        <div className={cn(
          "relative z-[10000] flex flex-col rounded-lg bg-white shadow-lg",
          "w-[85%] max-w-[800px]", // Default width classes
          className // className comes last to override defaults
        )}>
          {children}
        </div>
      </div>
    </div>
  );
}

export function DialogContent({ children, onClose, className = "" }) {
  return (
    <div className={cn("w-12/12 mx-auto p-6 flex flex-col items-center justify-center", className)}>
      {children}
      {onClose && (
        <button
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>
      )}
    </div>
  );
}

export function DialogHeader({ className = "", ...props }) {
  return (
    <div
      className={cn("flex flex-col items-center space-y-1.5 text-center w-full", className)}
      {...props}
    />
  );
}

export function DialogTitle({ className = "", ...props }) {
  return (
    <h2
      className={cn("text-lg font-semibold leading-none text-center", className)}
      {...props}
    />
  );
}

export function DialogDescription({ className = "", ...props }) {
  return (
    <p
      className={cn("text-sm text-gray-500 text-center", className)}
      {...props}
    />
  );
}

export function DialogFooter({ className = "", ...props }) {
  return (
    <div
      className={cn(
        "flex flex-col-reverse sm:flex-row sm:justify-center sm:space-x-2 w-full mt-4",
        className
      )}
      {...props}
    />
  );
}