import React, { useState, useRef, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { 
  Upload, X, AlertCircle, CheckCircle2, FileUp, 
  HelpCircle, MapPin, ChevronDown, ChevronRight 
} from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Alert, AlertDescription } from "../../ui/alert";
import { Textarea } from "../../ui/text-area";
import { ScrollArea } from "../../ui/scroll-area";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../ui/collapsible";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";
import Papa from 'papaparse';
import { zipBoundariesClient } from '../../../zipBoundariesClient';

const BulkZipcodeModal = ({ 
  isOpen, 
  onClose, 
  onImport,
  onDelete,
  existingZipcodes = [],
  zipcodeData = []
}) => {
  const [activeTab, setActiveTab] = useState('import');
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState('');
  const [validZipcodes, setValidZipcodes] = useState([]);
  const [duplicateZipcodes, setDuplicateZipcodes] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [expandedStates, setExpandedStates] = useState(new Set());
  const [expandedCities, setExpandedCities] = useState(new Set());

  const fileInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const cityInputRef = useRef(null);

  // Group zipcodes by state and city for management view
  const organizedData = useMemo(() => {
    const grouped = zipcodeData.reduce((acc, { zipcode, city, state }) => {
      if (!acc[state]) {
        acc[state] = {};
      }
      if (!acc[state][city]) {
        acc[state][city] = [];
      }
      acc[state][city].push(zipcode);
      return acc;
    }, {});

    return Object.entries(grouped)
      .sort(([stateA], [stateB]) => stateA.localeCompare(stateB))
      .map(([state, cities]) => ({
        state,
        cities: Object.entries(cities)
          .sort(([cityA], [cityB]) => cityA.localeCompare(cityB))
          .map(([city, zipcodes]) => ({
            city,
            zipcodes: zipcodes.sort()
          }))
      }));
  }, [zipcodeData]);

  useEffect(() => {
    const cleanup = () => {
      if (autocompleteRef.current) {
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
        autocompleteRef.current = null;
      }
    };

    if (isOpen && activeTab === 'import' && window.google && cityInputRef.current) {
      cleanup();

      const options = {
        types: ['(cities)'],
        componentRestrictions: { country: 'us' },
        fields: ['address_components', 'geometry']
      };

      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        cityInputRef.current,
        options
      );

      autocompleteRef.current.addListener('place_changed', async () => {
        const place = autocompleteRef.current.getPlace();
        if (!place.geometry) return;

        try {
          setIsProcessing(true);
          setError('');

          let city = '';
          let state = '';
          place.address_components.forEach(component => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            }
            if (component.types.includes('administrative_area_level_1')) {
              state = component.short_name;
            }
          });

          if (!city || !state) {
            throw new Error('Could not determine city or state from selection');
          }

          const { data: zipcodes, error: fetchError } = await zipBoundariesClient
            .from('zip_boundaries')
            .select('zipcode')
            .ilike('city', city)
            .eq('state', state);

          if (fetchError) throw fetchError;

          if (zipcodes?.length) {
            const zipcodesArray = zipcodes.map(record => record.zipcode);
            validateZipcodes(zipcodesArray);
            cityInputRef.current.value = '';
          } else {
            setError('No zipcodes found for this city');
          }
        } catch (error) {
          console.error('Error fetching city zipcodes:', error);
          setError('Failed to fetch zipcodes for this city');
        } finally {
          setIsProcessing(false);
        }
      });
    }

    return cleanup;
  }, [isOpen, activeTab]);

  const validateZipcodes = (rawZipcodes) => {
    const zipArray = Array.from(new Set(
      rawZipcodes
        .map(zip => zip.toString().trim())
        .filter(Boolean)
    ));

    const zipRegex = /^\d{5}$/;
    const valid = [];
    const invalid = [];
    const duplicates = [];

    zipArray.forEach(zip => {
      if (!zipRegex.test(zip)) {
        invalid.push(zip);
      } else if (existingZipcodes.includes(zip)) {
        duplicates.push(zip);
      } else if (valid.includes(zip)) {
        duplicates.push(zip);
      } else {
        valid.push(zip);
      }
    });

    setValidZipcodes(valid);
    setDuplicateZipcodes(duplicates);

    if (invalid.length > 0) {
      setError(`Invalid zipcodes found: ${invalid.join(', ')}`);
      return false;
    }

    return true;
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    setError('');
    if (text) {
      const rawZipcodes = text.split(/[\s,;\n]+/).filter(Boolean);
      validateZipcodes(rawZipcodes);
    } else {
      setValidZipcodes([]);
      setDuplicateZipcodes([]);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsProcessing(true);
    setError('');
    setValidZipcodes([]);
    setDuplicateZipcodes([]);
    event.target.value = '';

    try {
      const results = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          complete: (results) => resolve(results),
          error: (error) => reject(error),
          skipEmptyLines: true,
          transform: (value) => value.trim()
        });
      });

      const zipcodes = results.data
        .flat()
        .map(val => val.toString().trim())
        .filter(Boolean);

      validateZipcodes(zipcodes);
    } catch (error) {
      console.error('Error parsing CSV:', error);
      setError('Failed to parse CSV file. Please check the file format.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleImport = () => {
    if (validZipcodes.length > 0) {
      onImport(validZipcodes);
      handleClose();
    }
  };

  const handleClose = () => {
    setInputText('');
    setError('');
    setValidZipcodes([]);
    setDuplicateZipcodes([]);
    setActiveTab('import');
    if (cityInputRef.current) {
      cityInputRef.current.value = '';
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px] p-0">
        <DialogHeader className="px-6 py-4 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <DialogTitle>Manage Service Areas</DialogTitle>
            {activeTab === 'import' && (
              <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => fileInputRef.current?.click()}
              >
                <FileUp className="w-4 h-4" />
                <span>Upload CSV</span>
              </Button>
            )}
          </div>
        </DialogHeader>

        {/* Tabs */}
        <div className="px-6 border-b border-gray-100">
          <div className="flex gap-6">
            <button
              onClick={() => setActiveTab('import')}
              className={`py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'import'
                  ? 'border-gray-900 text-gray-900'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              Import
            </button>
            <button
              onClick={() => setActiveTab('manage')}
              className={`py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'manage'
                  ? 'border-gray-900 text-gray-900'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              Manage Existing
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          {activeTab === 'import' ? (
            <>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Enter Zipcodes
                  </label>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <HelpCircle className="w-4 h-4 text-gray-400 cursor-help" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Zipcodes should be 5 digits and can be separated by spaces, commas, or new lines</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <Textarea
                  value={inputText}
                  onChange={handleTextChange}
                  placeholder="Enter zipcodes separated by spaces, commas, or new lines..."
                  className="h-32"
                  disabled={isProcessing}
                />
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Import by City/Town
                </label>
                <div className="relative">
                  <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4" />
                  <input
                    ref={cityInputRef}
                    type="text"
                    placeholder="Search for a city or town..."
                    className="w-full pl-10 pr-4 py-2.5 text-sm bg-white border border-gray-200 rounded-lg focus:ring-1 focus:ring-gray-200 focus:outline-none"
                    disabled={isProcessing}
                  />
                </div>
                <p className="text-sm text-gray-500">
                  Search and select a city to import all its zipcodes
                </p>
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              {validZipcodes.length > 0 && (
                <Alert>
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <AlertDescription>
                    {validZipcodes.length} valid zipcodes found
                  </AlertDescription>
                </Alert>
              )}

              {duplicateZipcodes.length > 0 && (
                <Alert variant="warning">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>
                    {duplicateZipcodes.length} duplicate zipcodes will be skipped: {duplicateZipcodes.join(', ')}
                  </AlertDescription>
                </Alert>
              )}
            </>
          ) : (
            <ScrollArea className="h-[400px] pr-4">
              <div className="space-y-4">
                {organizedData.map(({ state, cities }) => (
                  <Collapsible
                    key={state}
                    open={expandedStates.has(state)}
                    onOpenChange={() => {
                      const newExpanded = new Set(expandedStates);
                      if (newExpanded.has(state)) {
                        newExpanded.delete(state);
                      } else {
                        newExpanded.add(state);
                      }
                      setExpandedStates(newExpanded);
                    }}
                  >
                    <CollapsibleTrigger className="flex items-center w-full p-2 bg-gray-100 rounded-lg hover:bg-gray-200">
                      {expandedStates.has(state) ? (
                        <ChevronDown className="w-4 h-4 mr-2" />
                      ) : (
                        <ChevronRight className="w-4 h-4 mr-2" />
                      )}
                      <span className="font-medium">{state}</span>
                      <span className="ml-2 text-sm text-gray-500">
                        ({cities.reduce((acc, city) => acc + city.zipcodes.length, 0)} zipcodes)
                      </span>
                    </CollapsibleTrigger>

                    <CollapsibleContent className="pl-6 mt-2 space-y-3">
                      {cities.map(({ city, zipcodes }) => (
                        <Collapsible
                          key={`${state}-${city}`}
                          open={expandedCities.has(`${state}-${city}`)}
                          onOpenChange={() => {
                            const newExpanded = new Set(expandedCities);
                            const key = `${state}-${city}`;
                            if (newExpanded.has(key)) {
                              newExpanded.delete(key);
                            } else {
                              newExpanded.add(key);
                            }
                            setExpandedCities(newExpanded);
                          }}
                        >
                          <CollapsibleTrigger className="flex items-center w-full p-2 hover:bg-gray-50 rounded-md">
                            {expandedCities.has(`${state}-${city}`) ? (
                              <ChevronDown className="w-4 h-4 mr-2" />
                            ) : (
                              <ChevronRight className="w-4 h-4 mr-2" />
                            )}
                            <span>{city}</span>
                            <span className="ml-2 text-sm text-gray-500">
                              ({zipcodes.length} zipcodes)
                            </span>
                          </CollapsibleTrigger>

                          <CollapsibleContent className="pl-8 mt-2">
                            <div className="flex flex-wrap gap-2">
                              {zipcodes.map((zipcode) => (
                                <div
                                  key={zipcode}
                                  className="flex items-center bg-gray-100 rounded-full px-3 py-1"
                                >
                                  <span className="text-sm">{zipcode}</span>
                                  <button
                                    onClick={() => {
                                      if (confirm(`Are you sure you want to remove ${zipcode}?`)) {
                                        onDelete(zipcode);
                                      }
                                    }}
                                    className="ml-2 text-gray-500 hover:text-red-500"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </CollapsibleContent>
                        </Collapsible>
                      ))}
                    </CollapsibleContent>
                  </Collapsible>
                ))}
              </div>
            </ScrollArea>
          )}
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 px-6 py-4 border-t border-gray-100">
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          {activeTab === 'import' && (
            <Button 
              onClick={handleImport} 
              disabled={validZipcodes.length === 0 || isProcessing}
              className="bg-gray-900 text-white hover:bg-gray-800"
            >
              {isProcessing ? (
                <span className="flex items-center gap-2">
                  <Upload className="w-4 h-4 animate-pulse" />
                  Processing...
                </span>
              ) : (
                `Import ${validZipcodes.length} Zipcodes`
              )}
            </Button>
          )}
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          accept=".csv"
          className="hidden"
        />
      </DialogContent>
    </Dialog>
  );
};

export default BulkZipcodeModal;
