import React, { forwardRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from "../../utils";

const textareaVariants = {
  initial: { 
    opacity: 0,
    scale: 0.98
  },
  animate: { 
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.2
    }
  }
};

const labelVariants = {
  initial: { 
    opacity: 0,
    y: -8
  },
  animate: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: {
    opacity: 0,
    y: -8,
    transition: {
      duration: 0.2
    }
  }
};

const errorMessageVariants = {
  initial: { 
    opacity: 0,
    height: 0,
    y: -4
  },
  animate: { 
    opacity: 1,
    height: 'auto',
    y: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1]
      },
      opacity: {
        duration: 0.2,
        delay: 0.1
      }
    }
  },
  exit: { 
    opacity: 0,
    height: 0,
    y: -4,
    transition: {
      height: {
        duration: 0.2,
        ease: [0.16, 1, 0.3, 1]
      },
      opacity: {
        duration: 0.1
      }
    }
  }
};

export const Textarea = forwardRef(({
  className,
  error,
  label,
  required,
  disabled,
  id,
  rows = 3,
  maxLength,
  showCount = false,
  value = '',
  onChange,
  ...props
}, ref) => {
  const characterCount = value?.length || 0;

  return (
    <motion.div 
      className="space-y-2"
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <AnimatePresence mode="wait">
        {label && (
          <motion.label
            htmlFor={id}
            variants={labelVariants}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
            {required && <span className="text-red-500 ml-1">*</span>}
          </motion.label>
        )}
      </AnimatePresence>

      <div className="relative">
        <motion.textarea
          rows={rows}
          maxLength={maxLength}
          className={cn(
            // Base styles
            "w-full px-3 py-2 text-sm transition-colors duration-200",
            "bg-gray-50 border border-gray-300 rounded-md",
            "placeholder:text-gray-400 resize-none",

            // Focus styles
            "focus:outline-none focus:ring-1 focus:ring-gray-200 focus:border-gray-900",

            // Disabled styles
            disabled && "opacity-50 cursor-not-allowed bg-gray-100",

            // Error styles
            error && "border-red-500 focus:ring-red-50 focus:border-red-500",

            // Character count padding
            showCount && "pb-8",

            className
          )}
          ref={ref}
          disabled={disabled}
          value={value}
          onChange={onChange}
          {...props}
        />

        {/* Character Count */}
        {showCount && (
          <div className="absolute bottom-2 right-3 text-xs text-gray-400">
            {characterCount}
            {maxLength && ` / ${maxLength}`}
          </div>
        )}
      </div>

      <AnimatePresence mode="wait">
        {error && (
          <motion.div
            variants={errorMessageVariants}
            className="text-xs text-red-500"
          >
            {error}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

Textarea.displayName = "Textarea";

export default Textarea;