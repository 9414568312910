import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import { createClient } from '@supabase/supabase-js';
import { motion, AnimatePresence } from 'framer-motion';
import {
  MapPin,
  Clock,
  Calendar,
  DollarSign,
  Truck,
  Info,
  Plus,
  X,
  Settings,
  Loader2,
  Upload,
  Table,
  Sparkles,
  HelpCircle  
} from 'lucide-react';
import { Switch } from "../../ui/switch";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/text-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import { Button } from "../..//ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../ui/collapsible";
import { cn } from "../../../utils";
import OtherInfoPanel from "./OtherInfoPanel";
import { ZipcodeSelectionButton } from './ZipcodeSelectionModal';
import ZipcodeCoverageMap from './ZipcodeCoverageMap';
import BulkZipcodeModal from './BulkZipcodeModal';
import { zipBoundariesClient } from '../../../zipBoundariesClient';
import { 
  ZONE_COLORS,
  ZONE_BG_COLORS,
  COLOR_PALETTE, 
  DEFAULT_COLOR,
  DEFAULT_BG_COLOR,
  getBgColor,
  getZoneColor
} from '../../../utils/zoneColors';
import FAQPanel from './FAQPanel';

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];



const formatTimeDisplay = (time24) => {
  if (!time24) return '';
  try {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).toLowerCase();
  } catch (error) {
    return time24;
  }
};

const TIME_OPTIONS = Array.from({ length: 48 }).map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;
  return {
    value: time24,
    label: formatTimeDisplay(time24)
  };
});

const modalVariants = {
  hidden: { 
    opacity: 0, 
    y: 10,
    scale: 0.98
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1
    }
  },
  exit: { 
    opacity: 0,
    y: -10,
    scale: 0.98,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0,
    y: 10,
    scale: 0.98
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0,
    x: -10,
    transition: {
      duration: 0.2,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

// Animations
const tabVariants = {
  initial: { opacity: 0, x: -10 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 10 }
};

const TabButton = ({ active, icon: Icon, label, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={cn(
      "flex items-center gap-2 px-4 py-2 rounded-md transition-colors whitespace-nowrap",
      active 
        ? "bg-gray-900 text-white"
        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
    )}
  >
    <Icon className="w-4 h-4" />
    <span className="text-sm font-medium">{label}</span>
  </motion.button>
);

const ZoneTab = ({
  zone,
  isActive,
  onSelect,
  onDelete,
  isDefault = false,
  style = {}
}) => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -20 }}
    className={cn(
      "group relative flex items-center gap-2 px-4 py-2 rounded-md cursor-pointer transition-all",
      isActive
        ? isDefault 
          ? "bg-gray-900 text-white" 
          : `text-white`
        : "hover:bg-gray-100"
    )}
    style={{
      ...style,
      backgroundColor: isActive && !isDefault ? style['--zone-color'] : undefined,
      borderColor: isActive && !isDefault ? style['--zone-color'] : undefined
    }}
    onClick={onSelect}
  >
    <span className="text-sm font-medium">
      {isDefault ? 'Default' : zone.name || `Zone ${zone.id}`}
    </span>
    {!isDefault && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete(zone.id);
        }}
        className="opacity-0 group-hover:opacity-100 p-1 hover:bg-gray-200 rounded-full transition-all"
      >
        <X className="w-4 h-4" />
      </button>
    )}
  </motion.div>
);

// Frequency Discounts Modal
const FrequencyDiscountsModal = ({
  isOpen,
  onClose,
  regularPrice,
  frequencies,
  onSave
}) => {
  const [localFrequencies, setLocalFrequencies] = useState(frequencies);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && Object.values(localFrequencies).some(val => val !== '')) {
          if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
            onClose();
          }
        } else {
          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Frequency Discounts</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <span className="text-sm font-medium">One-Time</span>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                value={regularPrice}
                disabled
                className="w-24"
              />
              <span className="text-sm text-gray-600">/lb</span>
            </div>
          </div>

          {['Monthly', 'Biweekly', 'Weekly'].map((frequency) => (
            <div
              key={frequency}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <span className="text-sm font-medium">{frequency}</span>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <Input
                  type="number"
                  value={localFrequencies[frequency.toLowerCase()] || ''}
                  onChange={(e) => setLocalFrequencies(prev => ({
                    ...prev,
                    [frequency.toLowerCase()]: e.target.value
                  }))}
                  className="w-24"
                  min="0"
                  step="0.01"
                />
                <span className="text-sm text-gray-600">/lb</span>
              </div>
            </div>
          ))}

          <div className="flex justify-end">
            <motion.button
              onClick={() => {
                onSave(localFrequencies);
                onClose();
              }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-gray-900 text-white rounded-md"
            >
              Save Changes
            </motion.button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const CSVMappingModal = ({
  isOpen,
  onClose,
  csvData,
  onConfirm,
  showPerField = false
}) => {
  const [hasHeaders, setHasHeaders] = useState(true);
  const [mapping, setMapping] = useState({
    name: '',
    price: '',
    per: ''
  });

  // Get the headers (first row)
  const headers = csvData?.[0] || {};
  // Get preview data from second row if headers enabled, first row if not
  const previewRow = hasHeaders ? csvData?.[1] : csvData?.[0];

  // Get column options from the first row
  const columnOptions = csvData?.[0] ? Object.entries(csvData[0]).map(([key, value]) => ({
    value: key,
    label: value ? value.trim() : ''
  })) : [];

  // Reset mapping when modal opens
  useEffect(() => {
    if (isOpen) {
      setHasHeaders(true);
      setMapping({
        name: '',
        price: '',
        per: ''
      });
    }
  }, [isOpen]);

  const handleConfirm = () => {
    // Skip first row if hasHeaders is true
    const dataToProcess = hasHeaders ? csvData.slice(1) : csvData;

    const cleanString = (str) => {
      if (!str) return '';
      // Remove quotes and commas from start and end, and trim whitespace
      return str.toString().replace(/^["',\s]+|["',\s]+$/g, '').trim();
    };

    const processPrice = (price) => {
      if (!price) return 0;
      // Remove $ and clean the string
      const cleanPrice = price.toString().replace(/[$,]/g, '').trim();
      // Convert to float and round to 2 decimal places
      return parseFloat(parseFloat(cleanPrice).toFixed(2)) || 0;
    };

    const processedData = dataToProcess.map(row => ({
      name: cleanPreviewValue(row[mapping.name]) || '',
      price: processPrice(row[mapping.price]),
      per: showPerField ? cleanPreviewValue(row[mapping.per]) || 'order' : undefined
    }));

    onConfirm(processedData);
    onClose();
  };

  const isValid = mapping.name && mapping.price && (!showPerField || mapping.per);

  // Function to clean preview value
  const cleanPreviewValue = (value) => {
    if (!value) return '';
    const cleaned = value.toString()
      .replace(/['"]+/g, '')  // Remove quotes
      .trim();
    return cleaned;
  };

  // Process price for preview display
  const formatPreviewPrice = (price) => {
    if (!price) return '';
    // Remove any existing $ and clean the string
    const cleanPrice = price.toString().replace(/[$]/g, '').trim();
    // Return with single $
    return `$${cleanPrice}`;
  };


  // Function to get selected value label
  const getSelectedLabel = (key) => {
    const option = columnOptions.find(opt => opt.value === mapping[key]);
    return option ? cleanPreviewValue(option.label) : 'Select column';
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[750px]">
        <DialogHeader className="px-6 py-4 border-b border-gray-200">
          <DialogTitle className="flex items-center gap-2 text-gray-900 text-xl font-semibold">
            <Table className="w-5 h-5" />
            Map CSV Columns
          </DialogTitle>
        </DialogHeader>

        <div className="p-6 space-y-8">
          {/* Headers Toggle */}
          <div className="bg-gray-50 rounded-lg border border-gray-200 p-4">
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                <h3 className="text-sm font-medium text-gray-900">First Row Contains Headers</h3>
                <p className="text-sm text-gray-500">
                  Toggle this if your CSV's first row contains column names
                </p>
              </div>
              <Switch
                checked={hasHeaders}
                onCheckedChange={setHasHeaders}
              />
            </div>
          </div>

          {/* Column Mapping */}
          <div className="space-y-6">
            <h3 className="text-sm font-medium text-gray-900">Map Your Columns</h3>

            <div className="space-y-4">
              {/* Item Name Mapping */}
              <div className="grid grid-cols-[200px_1fr] gap-4 items-center">
                <div className="text-sm text-gray-700">Item Name Column</div>
                <Select
                  value={mapping.name}
                  onValueChange={(value) => setMapping({ ...mapping, name: value })}
                >
                  <SelectTrigger className="w-[240px]">
                    <SelectValue>
                      {cleanPreviewValue(getSelectedLabel('name'))}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">Select column</SelectItem>
                    {columnOptions.map((col) => (
                      <SelectItem key={col.value} value={col.value}>
                        {cleanPreviewValue(col.label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {/* Price Mapping */}
              <div className="grid grid-cols-[200px_1fr] gap-4 items-center">
                <div className="text-sm text-gray-700">Price Column</div>
                <Select
                  value={mapping.price}
                  onValueChange={(value) => setMapping({ ...mapping, price: value })}
                >
                  <SelectTrigger className="w-[240px]">
                    <SelectValue>
                      {cleanPreviewValue(getSelectedLabel('price'))}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">Select column</SelectItem>
                    {columnOptions.map((col) => (
                      <SelectItem key={col.value} value={col.value}>
                        {cleanPreviewValue(col.label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {/* Per Field Mapping (if needed) */}
              {showPerField && (
                <div className="grid grid-cols-[200px_1fr] gap-4 items-center">
                  <div className="text-sm text-gray-700">Per Column (Optional)</div>
                  <Select
                    value={mapping.per}
                    onValueChange={(value) => setMapping({ ...mapping, per: value })}
                  >
                    <SelectTrigger className="w-[240px]">
                      <SelectValue>
                        {cleanPreviewValue(getSelectedLabel('per'))}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="">Select column</SelectItem>
                      {columnOptions.map((col) => (
                        <SelectItem key={col.value} value={col.value}>
                          {cleanPreviewValue(col.label)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </div>

          {/* Preview Section */}
          <div className="space-y-3">
            <h4 className="text-sm font-medium text-gray-900">Preview</h4>
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600 font-sans">
                {mapping.name && mapping.price && (
                  <span>
                    {cleanPreviewValue(previewRow[mapping.name])} = {formatPreviewPrice(cleanPreviewValue(previewRow[mapping.price]))}
                    {showPerField && mapping.per && ` per ${cleanPreviewValue(previewRow[mapping.per])}`}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-end gap-3 px-6 py-4 border-t border-gray-200">
          <Button
            variant="outline"
            onClick={onClose}
            className="text-gray-700 bg-gray-100 hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={!isValid}
            className="bg-gray-900 text-white hover:bg-gray-800"
          >
            Import Data
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// Item Pricing Modal
const ItemPricingModal = ({
  isOpen,
  onClose,
  title,
  items,
  onSave,
  showPerField = false
}) => {
  const fileInputRef = useRef(null);
  const [localItems, setLocalItems] = useState(items.map(item => ({
    ...item,
    per: item.per || 'order',
    isPlusWeight: item.isPlusWeight || false  
  })));
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [parsedCSVData, setParsedCSVData] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;

      // First, split the text into lines manually to handle the headers
      const lines = text.split('\n').map(line => line.trim()).filter(line => line);
      const headerLine = lines[0];
      const dataLines = lines.slice(1);

      // Parse headers
      const headers = headerLine.split(',').map(h => h.trim());

      // Parse data rows
      const parsedData = dataLines.map(line => {
        const values = line.split(',').map(val => val.trim());
        // Create an object with index-based and header-based keys
        return values.reduce((obj, val, idx) => {
          obj[idx] = val;  // Numeric index for backward compatibility
          obj[headers[idx]] = val;  // Header-based key
          return obj;
        }, {});
      });

      // Add headers to the start of the data array
      const finalData = [
        headers.reduce((obj, header, idx) => {
          obj[idx] = header;
          return obj;
        }, {}),
        ...parsedData
      ];

      console.log('Processed CSV data:', finalData);
      setParsedCSVData(finalData);
      setShowMappingModal(true);
    };

    reader.onerror = () => {
      console.error('Error reading file');
      alert('Error reading file. Please try again.');
    };

    reader.readAsText(file);
    event.target.value = '';
  };

  const handleMappedData = (mappedData) => {
    setLocalItems(mappedData);
    setShowMappingModal(false);
  };

  const handleUpdateItem = (index, field, value) => {
    const newItems = [...localItems];
    newItems[index] = { 
      ...newItems[index], 
      [field]: field === 'price' ? parseFloat(value) || 0 : value 
    };
    setLocalItems(newItems);
  };

  const PER_OPTIONS = [
    { value: 'order', label: 'Order' },
    { value: 'pound', label: 'Pound' },
    { value: 'item', label: 'Item' }
  ];

  return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[900px] overflow-hidden">
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="h-full"
          >
            <DialogHeader className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <DialogTitle className="text-xl font-semibold text-gray-900">
                  {title}
                </DialogTitle>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => fileInputRef.current?.click()}
                  className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
                >
                  <Upload className="w-4 h-4 mr-2" />
                  Import CSV
                </motion.button>
              </div>
            </DialogHeader>

            <div className="p-6 space-y-6">
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ 
                  opacity: 1, 
                  y: 0,
                  transition: {
                    duration: 0.3,
                    delay: 0.1,
                    ease: [0.16, 1, 0.3, 1]
                  }
                }}
              >
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setLocalItems([...localItems, { name: '', price: 0, per: 'order' }])}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-800 transition-colors duration-200"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Item
                </motion.button>
              </motion.div>

              <div className="space-y-3 max-h-[400px] overflow-y-auto pr-2">
                <AnimatePresence mode="popLayout">
                  {localItems.map((item, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              layout
              className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg border border-gray-200 group hover:border-gray-300 hover:shadow-sm transition-all duration-200"
            >
              <Input
                placeholder="Item name"
                value={item.name}
                onChange={(e) => {
                  const newItems = [...localItems];
                  newItems[index].name = e.target.value;
                  setLocalItems(newItems);
                }}
                className="flex-1"
              />

              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500">$</span>
                <Input
                  type="number"
                  value={item.price}
                  onChange={(e) => {
                    const newItems = [...localItems];
                    newItems[index].price = parseFloat(e.target.value) || 0;
                    setLocalItems(newItems);
                  }}
                  className="w-24"
                  min="0"
                  step="0.01"
                />
                <span className="text-sm text-gray-500">+Weight?</span>
                <Switch 
                  checked={item.isPlusWeight || false}
                  onCheckedChange={(checked) => {
                    const newItems = [...localItems];
                    newItems[index].isPlusWeight = checked;
                    setLocalItems(newItems);
                  }}
                />
              </div>

              <motion.button
                onClick={() => {
                  const newItems = localItems.filter((_, i) => i !== index);
                  setLocalItems(newItems);
                }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-200"
              >
                <X className="w-4 h-4" />
              </motion.button>
            </motion.div>
                  ))}
                </AnimatePresence>
              </div>

              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept=".csv"
                className="hidden"
              />
            </div>

            <motion.div 
              className="flex justify-end gap-3 px-6 py-4 border-t border-gray-200"
              initial={{ opacity: 0, y: 10 }}
              animate={{ 
                opacity: 1, 
                y: 0,
                transition: {
                  delay: 0.2,
                  duration: 0.3,
                  ease: [0.16, 1, 0.3, 1]
                }
              }}
            >
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200"
              >
                Cancel
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  onSave(localItems);
                  onClose();
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                Save Changes
              </motion.button>
            </motion.div>
          </motion.div>
        </DialogContent>

        {showMappingModal && parsedCSVData && (
          <CSVMappingModal
            isOpen={showMappingModal}
            onClose={() => {
              setShowMappingModal(false);
              setParsedCSVData(null);
            }}
            csvData={parsedCSVData}
            onConfirm={handleMappedData}
            showPerField={showPerField}
          />
        )}
      </Dialog>
    );
  };

// Main PUD Service Panel
const PUDServicePanel = ({
  selectedLocation,
  onUpdateLocation,
  isServiceActive,
  zipBoundariesClient
}) => {
  const [zipData, setZipData] = useState({});
  // All PUD data is read from selectedLocation.services.pickupDelivery
  const pickupDelivery = selectedLocation?.services?.pickupDelivery || {
    general: {
      services: { laundry: true, dryCleaning: false },
      website: '',
      zipcodes: []
    },
    dryCleaning: {
      active: false,
      items: []
    },
    schedule: {
      useSameAsStore: true,
      customerChooseHours: false,
      turnaroundHours: 48,
      sameDayService: false,
      sameDayCutoff: '12:00'
    },
    pricing: {
      minimumOrder: { enabled: false, type: 'dollars', amount: 0 },
      regularPrice: 0,
      sameDayPrice: 0,
      missedPickupFee: { enabled: false, amount: 0 },
      frequencyDiscounts: {
        enabled: false,
        discounts: { monthly: '', biweekly: '', weekly: '' }
      },
      specialtyItems: { enabled: false, items: [] },
      extras: { enabled: false, items: [] }
    },
    instructions: {
      firstPickup: ''
    },
    zones: [],
    otherInfo: []
  };

  useEffect(() => {
    // Verify client and table
    const checkDatabase = async () => {
      try {
        const { data, error } = await zipBoundariesClient
          .from('zip_boundaries')
          .select('count')
          .limit(1);

        console.log('Database check:', { data, error });
      } catch (err) {
        console.error('Database connection error:', err);
      }
    };

    checkDatabase();
  }, [zipBoundariesClient]);

  // Add this useEffect to fetch data for all zipcodes at once
  useEffect(() => {
    const fetchZipData = async () => {
      if (!zipBoundariesClient) {
        console.error('zipBoundariesClient is not initialized');
        return;
      }

      if (pickupDelivery.general?.zipcodes?.length) {
        try {
          const { data, error } = await zipBoundariesClient
            .from('zip_boundaries')
            .select('zipcode, city, state')
            .in('zipcode', pickupDelivery.general.zipcodes);

          if (!error && data) {
            const zipMap = data.reduce((acc, { zipcode, city, state }) => {
              acc[zipcode] = { city, state };
              return acc;
            }, {});
            setZipData(zipMap);
          }
        } catch (err) {
          console.error('Error fetching zip data:', err);
        }
      }
    };

    fetchZipData();
  }, [pickupDelivery.general?.zipcodes]);

  // These functions will now use the cached data
  const getCityForZipcode = (zipcode) => {
    return zipData[zipcode]?.city || '';
  };

  const getStateForZipcode = (zipcode) => {
    return zipData[zipcode]?.state || '';
  };

  // UI states
  const [activeZoneId, setActiveZoneId] = useState('default');
  const [activeTab, setActiveTab] = useState('general');
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [showSpecialtyModal, setShowSpecialtyModal] = useState(false);
  const [showExtrasModal, setShowExtrasModal] = useState(false);
  const [showDryCleaningModal, setShowDryCleaningModal] = useState(false);
  const [otherInfoTab, setOtherInfoTab] = useState('additional');

  const [zipInput, setZipInput] = useState('');
  const [zipError, setZipError] = useState('');
  const [showAllZipcodes, setShowAllZipcodes] = useState(false);
  const [showBulkImportModal, setShowBulkImportModal] = useState(false);

  if (!isServiceActive) {
    return (
      <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
        <p className="text-sm text-gray-600 text-center">
          Enable Pickup & Delivery service to configure its settings
        </p>
      </div>
    );
  }

  // Zones array
  const zones = pickupDelivery.zones || [];

  // -------------------------------------------------------------------
  // Add/delete zone
  // -------------------------------------------------------------------
  const handleAddZone = () => {
    // Get next sequential ID based on existing zones
    const maxId = zones.reduce((max, zone) => {
      const zoneId = typeof zone.id === 'number' ? zone.id : parseInt(zone.id, 10);
      return isNaN(zoneId) ? max : Math.max(max, zoneId);
    }, 0);

    const newZoneId = maxId + 1;  // Next sequential ID

    const newZone = {
      id: newZoneId,  // Use sequential ID instead of timestamp
      name: `Zone ${newZoneId}`,
      zipcodes: [],
      scheduleSameAsDefault: true,
      pricingSameAsDefault: true,
      services: {
        laundry: true,
        dryCleaning: true
      },
      schedule: {},
      pricing: {}
    };

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          zones: [...zones, newZone]
        }
      }
    });
    setActiveZoneId(newZoneId);
    setActiveTab('general');
  };

  const handleDeleteZone = (zoneId) => {
    const updatedZones = zones.filter(z => z.id !== zoneId);
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          zones: updatedZones
        }
      }
    });
    if (activeZoneId === zoneId) {
      setActiveZoneId('default');
    }
  };

  // -------------------------------------------------------------------
  // Helper: reassign a zipcode from currentZoneId → targetZoneId
  // -------------------------------------------------------------------
  const handleZipcodeZoneChange = (zipcode, targetZoneId, currentZoneId) => {
    console.log('handleZipcodeZoneChange called with:', {
      zipcode,
      targetZoneId,
      currentZoneId
    });

    // 1) Convert zone IDs from string => number, unless they're 'default'
    const tID = (targetZoneId === 'default') ? 'default' : parseInt(targetZoneId, 10);
    const cID = (currentZoneId === 'default') ? 'default' : parseInt(currentZoneId, 10);

    // 2) Clone arrays
    let updatedZones = [...zones];
    let updatedDefaultZips = [...(pickupDelivery.general?.zipcodes || [])];

    // Log pre-state
    console.log('Before update:');
    console.log('   default zips =', updatedDefaultZips);
    zones.forEach(z => {
      console.log(`   zone ${z.id} =>`, z.zipcodes);
    });

    // 1) remove from old location
    if (cID === 'default') {
      updatedDefaultZips = updatedDefaultZips.filter(z => z !== zipcode);
    } else {
      updatedZones = updatedZones.map(z => {
        if (z.id === cID) {
          return {
            ...z,
            zipcodes: z.zipcodes.filter(code => code !== zipcode)
          };
        }
        return z;
      });
    }

    // 2) add to new location
    if (tID === 'default') {
      updatedDefaultZips.push(zipcode);
    } else {
      updatedZones = updatedZones.map(z => {
        if (z.id === tID) {
          return {
            ...z,
            zipcodes: [...z.zipcodes, zipcode]
          };
        }
        return z;
      });
    }


    // Log post-state
    console.log('After update:');
    console.log('   default zips =', updatedDefaultZips);
    updatedZones.forEach(z => {
      console.log(`   zone ${z.id} =>`, z.zipcodes);
    });

    // 3) push the updated arrays back up
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            zipcodes: updatedDefaultZips
          },
          zones: updatedZones
        }
      }
    });
  };

  // -------------------------------------------------------------------
  // "Default" tab sections: general, schedule, pricing, instructions, otherInfo
  // -------------------------------------------------------------------
  const handleToggleLaundryService = (checked) => {
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            services: {
              ...pickupDelivery.general?.services,
              laundry: checked
            }
          }
        }
      }
    });
  };

  const handleToggleDryCleaningService = (checked) => {
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            services: {
              ...pickupDelivery.general?.services,
              dryCleaning: checked
            }
          },
          dryCleaning: {
            ...(pickupDelivery.dryCleaning || {}),
            active: checked,
            items: checked ? (pickupDelivery.dryCleaning?.items || []) : []
          }
        }
      }
    });
  };

  const handleSaveDryCleaningItems = (items) => {
    // Update the dry cleaning items which will be shared across all zones
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          dryCleaning: {
            ...pickupDelivery.dryCleaning,
            items
          }
        }
      }
    });
    setShowDryCleaningModal(false);
  };

  const renderDryCleaningContent = () => {
    // Get dry cleaning items with a safe default
    const items = pickupDelivery.dryCleaning?.items || [];

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">Dry Cleaning Items</h3>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setShowDryCleaningModal(true)}
            className="flex items-center gap-2 px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800"
          >
            <span className="text-sm">Configure Items</span>
          </motion.button>
        </div>

        {items.length === 0 ? (
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-sm text-gray-500 text-center">
              No dry cleaning items configured yet
            </p>
          </div>
        ) : (
          <div className="space-y-3">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200"
              >
                <span className="text-sm font-medium text-gray-900">{item.name}</span>
                <span className="text-sm text-gray-600">${item.price}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleZipcodeSelect = (zipcode) => {
    console.log('Adding zipcode:', zipcode);
    console.log('Current zipcodes:', pickupDelivery.general?.zipcodes);

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            zipcodes: [...(pickupDelivery.general?.zipcodes || []), zipcode]
          }
        }
      }
    });

    console.log('Updated zipcodes:', [...(pickupDelivery.general?.zipcodes || []), zipcode]);
  };

  const renderDefaultGeneral = () => {
    const { general } = pickupDelivery;

    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-6">
          {/* Services Offered */}
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Services Offered</h3>
            <div className="flex items-center gap-4">
              <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <span className="text-sm text-gray-700">Laundry Service</span>
                <Switch
                  checked={general?.services?.laundry}
                  onCheckedChange={handleToggleLaundryService}
                />
              </div>
              <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <span className="text-sm text-gray-700">Dry Cleaning Service</span>
                <Switch
                  checked={general?.services?.dryCleaning}
                  onCheckedChange={handleToggleDryCleaningService}
                />
              </div>
            </div>
          </div>

          {/* Online Ordering Website */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">
              Online Ordering Website
            </label>
            <Input
              type="url"
              value={general?.website || ''}
              onChange={(e) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      general: {
                        ...pickupDelivery.general,
                        website: e.target.value
                      }
                    }
                  }
                });
              }}
              placeholder="https://your-ordering-website.com"
            />
          </div>

          {/* Coverage Areas */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">
              Coverage Areas
            </label>

            {/* Zipcode Input and Bulk Import */}
            <div className="grid grid-cols-[1fr_auto_auto] gap-2">
              <form 
                className="flex col-span-2 gap-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log('Form submitted with zipcode:', zipInput);

                  // Check for valid zipcode format
                  if (zipInput.length === 5 && /^\d+$/.test(zipInput)) {
                    // Check if zipcode already exists in general zipcodes
                    if (pickupDelivery.general?.zipcodes?.includes(zipInput)) {
                      setZipError('This zipcode is already in your default zone');
                      return;
                    }

                    // Check if zipcode exists in any other zone
                    const existingInZone = zones.find(zone => 
                      zone.zipcodes?.includes(zipInput)
                    );

                    if (existingInZone) {
                      setZipError(`This zipcode is already in zone "${existingInZone.name || `Zone ${existingInZone.id}`}"`);
                      return;
                    }

                    // If we get here, the zipcode is unique
                    handleZipcodeSelect(zipInput);
                    setZipInput('');
                    setZipError('');
                  } else {
                    setZipError('Please enter a valid 5-digit zipcode');
                  }
                }}
              >
                <div className="flex-1">
                  <Input
                    type="text"
                    value={zipInput}
                    onChange={(e) => {
                      setZipInput(e.target.value);
                      if (zipError) setZipError('');
                    }}
                    placeholder="Enter 5-digit zipcode"
                    maxLength={5}
                    className={cn(zipError && "border-red-500")}
                  />
                  {zipError && (
                    <p className="text-xs text-red-500 mt-1">{zipError}</p>
                  )}
                </div>

                <Button 
                  type="submit"
                  variant="default"
                  className="bg-gray-900 text-white hover:bg-gray-800"
                >
                  Add
                </Button>
              </form>

              <Button
                variant="outline"
                onClick={() => setShowBulkImportModal(true)}
                className="flex items-center gap-2"
              >
                <Upload className="w-4 h-4" />
                <span>Bulk Updates</span>
              </Button>
            </div>

            {/* Zipcode Pills */}
            {pickupDelivery.general?.zipcodes?.length > 0 && (
              <div className="mt-4">
                <div className="flex flex-wrap gap-2 max-h-[72px] overflow-hidden relative">
                  {pickupDelivery.general.zipcodes.slice(0, 7).map((zip) => (
                    <div
                      key={zip}
                      className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm flex items-center gap-2"
                    >
                      {zip}
                      <button
                        onClick={() => {
                          onUpdateLocation({
                            ...selectedLocation,
                            services: {
                              ...selectedLocation.services,
                              pickupDelivery: {
                                ...pickupDelivery,
                                general: {
                                  ...pickupDelivery.general,
                                  zipcodes: pickupDelivery.general.zipcodes.filter(z => z !== zip)
                                }
                              }
                            }
                          });
                        }}
                        className="hover:text-blue-900"
                      >
                        <X className="w-3 h-3" />
                      </button>
                    </div>
                  ))}
                  {pickupDelivery.general.zipcodes.length > 7 && (
                    <div className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm">
                      +{pickupDelivery.general.zipcodes.length - 7} more
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Bulk Import Modal */}
          <BulkZipcodeModal
            isOpen={showBulkImportModal}
            onClose={() => setShowBulkImportModal(false)}
            onImport={(zipcodes) => {
              const updatedZipcodes = [
                ...(pickupDelivery.general?.zipcodes || []),
                ...zipcodes
              ];
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    general: {
                      ...pickupDelivery.general,
                      zipcodes: updatedZipcodes
                    }
                  }
                }
              });
            }}
            onDelete={(zipcode) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    general: {
                      ...pickupDelivery.general,
                      zipcodes: pickupDelivery.general.zipcodes.filter(z => z !== zipcode)
                    }
                  }
                }
              });
            }}
            existingZipcodes={pickupDelivery.general?.zipcodes || []}
            zipcodeData={(pickupDelivery.general?.zipcodes || []).map(zipcode => ({
              zipcode,
              city: getCityForZipcode(zipcode),
              state: getStateForZipcode(zipcode)
            }))}
          />
        </div>

        {/* Map container - give it proper height and ensure parent fills space */}
              <div className="h-full min-h-[600px]">
                <ZipcodeCoverageMap
                  zipcodes={[
                    ...(pickupDelivery.general?.zipcodes || []),
                    ...zones.flatMap(zone => zone.zipcodes || [])
                  ]}
                  zones={zones}
                  className="h-full"
                  zipBoundariesClient={zipBoundariesClient}  // Add this prop
                />
              </div>
            </div>
          );
        };

  const renderDefaultSchedule = () => {
    const { schedule } = pickupDelivery;
    return (
      <div className="space-y-6">
        {/* Use Same Hours as Store? */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">
              Use Same Hours as Store?
            </span>
          </div>
          <Switch
            checked={schedule?.useSameAsStore}
            onCheckedChange={(checked) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    schedule: {
                      ...pickupDelivery.schedule,
                      useSameAsStore: checked
                    }
                  }
                }
              });
            }}
          />
        </div>

        {/* If not same as store, day-by-day schedule */}
        {!schedule?.useSameAsStore && (
          <div className="grid grid-cols-2 gap-4">
            {DAYS_OF_WEEK.map((day) => {
              const dayKey = day.toLowerCase();
              const dayData = schedule[dayKey] || {};

              const handleDayOpenChange = (checked) => {
                const newData = {
                  isOpen: checked,
                  timeRanges: checked
                    ? [{ start: '09:00', end: '17:00' }]
                    : []
                };
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      schedule: {
                        ...pickupDelivery.schedule,
                        [dayKey]: newData
                      }
                    }
                  }
                });
              };

              return (
                <div
                  key={day}
                  className="p-4 bg-gray-50 rounded-lg space-y-4"
                >
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-gray-900">{day}</span>
                    <Switch
                      checked={!!dayData.isOpen}
                      onCheckedChange={handleDayOpenChange}
                    />
                  </div>

                  {dayData.isOpen && (
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <label className="text-xs text-gray-600">Opening Time</label>
                        <Select
                          value={dayData.timeRanges?.[0]?.start || '09:00'}
                          onValueChange={(value) => {
                            const updatedRanges = [{
                              ...dayData.timeRanges?.[0],
                              start: value
                            }];
                            onUpdateLocation({
                              ...selectedLocation,
                              services: {
                                ...selectedLocation.services,
                                pickupDelivery: {
                                  ...pickupDelivery,
                                  schedule: {
                                    ...pickupDelivery.schedule,
                                    [dayKey]: {
                                      ...dayData,
                                      timeRanges: updatedRanges
                                    }
                                  }
                                }
                              }
                            });
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {TIME_OPTIONS.map((time) => (
                              <SelectItem key={time.value} value={time.value}>
                                {time.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>

                      <div className="space-y-2">
                        <label className="text-xs text-gray-600">Closing Time</label>
                        <Select
                          value={dayData.timeRanges?.[0]?.end || '17:00'}
                          onValueChange={(value) => {
                            const updatedRanges = [{
                              ...dayData.timeRanges?.[0],
                              end: value
                            }];
                            onUpdateLocation({
                              ...selectedLocation,
                              services: {
                                ...selectedLocation.services,
                                pickupDelivery: {
                                  ...pickupDelivery,
                                  schedule: {
                                    ...pickupDelivery.schedule,
                                    [dayKey]: {
                                      ...dayData,
                                      timeRanges: updatedRanges
                                    }
                                  }
                                }
                              }
                            });
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {TIME_OPTIONS.map((time) => (
                              <SelectItem key={time.value} value={time.value}>
                                {time.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {/* Allow Customers to Choose Pickup/Delivery Times? */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">
              Allow Customers to Choose Pickup/Delivery Times?
            </span>
          </div>
          <Switch
            checked={schedule?.customerChooseHours}
            onCheckedChange={(checked) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    schedule: {
                      ...pickupDelivery.schedule,
                      customerChooseHours: checked
                    }
                  }
                }
              });
            }}
          />
        </div>

        {/* Turnaround Time */}
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Turnaround Time (hours)</label>
            <Input
              type="number"
              value={schedule?.turnaroundHours || ''}
              onChange={(e) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      schedule: {
                        ...pickupDelivery.schedule,
                        turnaroundHours: parseInt(e.target.value) || 1
                      }
                    }
                  }
                });
              }}
              min="1"
              className="w-full"
            />
          </div>
        </div>

        {/* Same-Day Service */}
        <div className="space-y-4">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">Offer Same-Day Service?</span>
            </div>
            <Switch
              checked={schedule?.sameDayService}
              onCheckedChange={(checked) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      schedule: {
                        ...pickupDelivery.schedule,
                        sameDayService: checked
                      }
                    }
                  }
                });
              }}
            />
          </div>

          {schedule?.sameDayService && (
            <div className="p-4 bg-gray-50 rounded-lg space-y-2">
              <label className="text-sm font-medium text-gray-700">Same-Day Cutoff Time</label>
              <Select
                value={schedule?.sameDayCutoff || '12:00'}
                onValueChange={(value) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        schedule: {
                          ...pickupDelivery.schedule,
                          sameDayCutoff: value
                        }
                      }
                    }
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {TIME_OPTIONS.map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDefaultPricing = () => {
    const { pricing } = pickupDelivery;
    return (
      <div className="space-y-6">
        {/* Minimum Order */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
              {pricing?.minimumOrder?.enabled && (
                <span className="text-xs text-gray-500">
                  {pricing?.minimumOrder?.type === 'dollars' ? 'In dollars' : 'In pounds'}
                </span>
              )}
            </div>
          </div>
          <Switch
            checked={pricing?.minimumOrder?.enabled}
            onCheckedChange={(checked) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    pricing: {
                      ...pickupDelivery.pricing,
                      minimumOrder: {
                        ...pickupDelivery.pricing.minimumOrder,
                        enabled: checked
                      }
                    }
                  }
                }
              });
            }}
          />
        </div>

        {pricing?.minimumOrder?.enabled && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="grid grid-cols-2 gap-4"
          >
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">Amount Type</label>
              <Select
                value={pricing?.minimumOrder?.type || 'dollars'}
                onValueChange={(value) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          minimumOrder: {
                            ...pickupDelivery.pricing.minimumOrder,
                            type: value
                          }
                        }
                      }
                    }
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue>
                    {pricing?.minimumOrder?.type === 'dollars'
                      ? 'Dollars ($)'
                      : 'Pounds (lbs)'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="dollars">Dollars ($)</SelectItem>
                  <SelectItem value="pounds">Pounds (lbs)</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">Minimum Order Amount</label>
              <div className="flex items-center gap-1">
                {pricing?.minimumOrder?.type === 'dollars' && (
                  <span className="text-sm text-gray-400">$</span>
                )}
                <Input
                  type="number"
                  value={pricing?.minimumOrder?.amount || 0}
                  onChange={(e) => {
                    onUpdateLocation({
                      ...selectedLocation,
                      services: {
                        ...selectedLocation.services,
                        pickupDelivery: {
                          ...pickupDelivery,
                          pricing: {
                            ...pickupDelivery.pricing,
                            minimumOrder: {
                              ...pickupDelivery.pricing.minimumOrder,
                              amount: parseFloat(e.target.value) || 0
                            }
                          }
                        }
                      }
                    });
                  }}
                  className="w-full"
                  min="0"
                  step="0.01"
                />
                {pricing?.minimumOrder?.type === 'pounds' && (
                  <span className="text-sm text-gray-400">lbs</span>
                )}
              </div>
            </div>
          </motion.div>
        )}

        {/* Regular Price */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Regular Price (per lb)</label>
          <div className="flex items-center gap-1">
            <span className="text-sm text-gray-400">$</span>
            <Input
              type="number"
              value={pricing?.regularPrice || 0}
              onChange={(e) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      pricing: {
                        ...pickupDelivery.pricing,
                        regularPrice: parseFloat(e.target.value) || 0
                      }
                    }
                  }
                });
              }}
              className="w-32"
              min="0"
              step="0.01"
            />
          </div>
        </div>

        {/* Same-Day Price */}
        {pickupDelivery.schedule?.sameDayService && (
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Same-Day Price (per lb)</label>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                value={pricing?.sameDayPrice || 0}
                onChange={(e) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          sameDayPrice: parseFloat(e.target.value) || 0
                        }
                      }
                    }
                  });
                }}
                className="w-32"
                min="0"
                step="0.01"
              />
            </div>
          </div>
        )}

        {/* Frequency Discounts */}
        <div className="space-y-4">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Frequency Discounts</span>
                {pricing?.frequencyDiscounts?.enabled && (
                  <span className="text-xs text-gray-500">
                    Discounted rates for recurring orders
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {pricing?.frequencyDiscounts?.enabled && (
                <motion.button
                  onClick={() => setShowFrequencyModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Configure
                </motion.button>
              )}
              <Switch
                checked={pricing?.frequencyDiscounts?.enabled}
                onCheckedChange={(checked) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          frequencyDiscounts: {
                            ...pickupDelivery.pricing.frequencyDiscounts,
                            enabled: checked
                          }
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          </div>

          {/* Specialty Items */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Settings className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">
                  Specialty Item Pricing
                </span>
                {pricing?.specialtyItems?.enabled && (
                  <span className="text-xs text-gray-500">
                    {pricing?.specialtyItems?.items.length} items configured
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {pricing?.specialtyItems?.enabled && (
                <motion.button
                  onClick={() => setShowSpecialtyModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Configure
                </motion.button>
              )}
              <Switch
                checked={pricing?.specialtyItems?.enabled}
                onCheckedChange={(checked) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          specialtyItems: {
                            ...pickupDelivery.pricing.specialtyItems,
                            enabled: checked,
                            items: checked
                              ? pickupDelivery.pricing.specialtyItems.items
                              : []
                          }
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          </div>

          {/* Extras */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Plus className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Extras Pricing</span>
                {pricing?.extras?.enabled && (
                  <span className="text-xs text-gray-500">
                    {pricing?.extras?.items.length} extras configured
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {pricing?.extras?.enabled && (
                <motion.button
                  onClick={() => setShowExtrasModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Configure
                </motion.button>
              )}
              <Switch
                checked={pricing?.extras?.enabled}
                onCheckedChange={(checked) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          extras: {
                            ...pickupDelivery.pricing.extras,
                            enabled: checked,
                            items: checked
                              ? pickupDelivery.pricing.extras.items
                              : []
                          }
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
        </div>

        {/* Frequency Discounts Modal */}
        <FrequencyDiscountsModal
          isOpen={showFrequencyModal}
          onClose={() => setShowFrequencyModal(false)}
          regularPrice={pricing?.regularPrice || 0}
          frequencies={pricing?.frequencyDiscounts?.discounts || {}}
          onSave={(discounts) => {
            onUpdateLocation({
              ...selectedLocation,
              services: {
                ...selectedLocation.services,
                pickupDelivery: {
                  ...pickupDelivery,
                  pricing: {
                    ...pickupDelivery.pricing,
                    frequencyDiscounts: {
                      ...pickupDelivery.pricing.frequencyDiscounts,
                      discounts
                    }
                  }
                }
              }
            });
          }}
        />

        {/* Specialty Items Modal */}
        <ItemPricingModal
          isOpen={showSpecialtyModal}
          onClose={() => setShowSpecialtyModal(false)}
          title="Specialty Item Pricing"
          items={pricing?.specialtyItems?.items || []}
          showPerField={false}  // Don't show "per" field for specialty items
          onSave={(items) => {
            onUpdateLocation({
              ...selectedLocation,
              services: {
                ...selectedLocation.services,
                pickupDelivery: {
                  ...pickupDelivery,
                  pricing: {
                    ...pickupDelivery.pricing,
                    specialtyItems: {
                      ...pickupDelivery.pricing.specialtyItems,
                      items
                    }
                  }
                }
              }
            });
          }}
        />

        {/* Extras Modal */}
        <ItemPricingModal
          isOpen={showExtrasModal}
          onClose={() => setShowExtrasModal(false)}
          title="Extras Pricing"
          items={pricing?.extras?.items || []}
          showPerField={true}  // Added this
          onSave={(items) => {
            onUpdateLocation({
              ...selectedLocation,
              services: {
                ...selectedLocation.services,
                pickupDelivery: {
                  ...pickupDelivery,
                  pricing: {
                    ...pickupDelivery.pricing,
                    extras: {
                      ...pickupDelivery.pricing.extras,
                      items
                    }
                  }
                }
              }
            });
          }}
        />
      </div>
    );
  };

  const renderDefaultInstructions = () => {
    const { instructions } = pickupDelivery;
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">
            First Pickup Requirements
          </label>
          <Textarea
            value={instructions?.firstPickup || ''}
            onChange={(e) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    instructions: {
                      ...pickupDelivery.instructions,
                      firstPickup: e.target.value
                    }
                  }
                }
              });
            }}
            placeholder="Enter special instructions for first pickup (bags, containers, etc.)"
            className="min-h-[200px]"
          />
        </div>
      </div>
    );
  };

  const renderDefaultOtherInfo = () => {
    return (
      <div className="space-y-6">
        {/* Sub-tabs for Other Info */}
        <div className="flex items-center gap-3">
          <TabButton
            active={otherInfoTab === 'additional'}
            icon={Info}
            label="Additional Info"
            onClick={() => setOtherInfoTab('additional')}
          />
          <TabButton
            active={otherInfoTab === 'faqs'}
            icon={HelpCircle}
            label="FAQs"
            onClick={() => setOtherInfoTab('faqs')}
          />
        </div>

        {/* Content based on selected sub-tab */}
        <AnimatePresence mode="wait">
          <motion.div
            key={otherInfoTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {otherInfoTab === 'additional' && (
              <OtherInfoPanel
                value={pickupDelivery.otherInfo || []}
                onChange={(newValue) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        otherInfo: newValue
                      }
                    }
                  });
                }}
                title="Additional Pickup & Delivery Information"
              />
            )}

            {otherInfoTab === 'faqs' && (
              <FAQPanel
                faqs={pickupDelivery.faqs || []}
                onChange={(newFaqs) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        faqs: newFaqs
                      }
                    }
                  });
                }}
                title="Pickup & Delivery FAQs"
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // Zone-specific logic
  // -------------------------------------------------------------------
  const renderZoneGeneral = (zone) => (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-6">
        {/* Zone Name */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Zone Name</label>
          <Input
            value={zone.name}
            onChange={(e) => {
              const updatedZones = zones.map(z =>
                z.id === zone.id ? { ...z, name: e.target.value } : z
              );
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    zones: updatedZones
                  }
                }
              });
            }}
            placeholder="Enter zone name"
          />
        </div>

        {/* Schedule & Pricing toggles */}
        <div className="flex items-center gap-4">
          <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Calendar className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                {zone.scheduleSameAsDefault ? 'Use Default Schedule' : 'Use Zone Schedule'}
              </span>
            </div>
            <Switch
              checked={zone.scheduleSameAsDefault}
              onCheckedChange={(checked) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? { ...z, scheduleSameAsDefault: checked } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
            />
          </div>

          <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                {zone.pricingSameAsDefault ? 'Use Default Pricing' : 'Use Zone Pricing'}
              </span>
            </div>
            <Switch
              checked={zone.pricingSameAsDefault}
              onCheckedChange={(checked) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? { ...z, pricingSameAsDefault: checked } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
            />
          </div>
        </div>
        {/* Services Toggles */}
        <div className="flex items-center gap-4">
          <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Sparkles className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                Laundry Service Available
              </span>
            </div>
            <Switch
              checked={zone.services?.laundry ?? true} // Default to true if not set
              onCheckedChange={(checked) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? {
                    ...z,
                    services: {
                      ...z.services,
                      laundry: checked
                    }
                  } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
            />
          </div>

          <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Sparkles className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                Dry Cleaning Available
              </span>
            </div>
            <Switch
              checked={zone.services?.dryCleaning ?? true} // Default to true if not set
              onCheckedChange={(checked) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? {
                    ...z,
                    services: {
                      ...z.services,
                      dryCleaning: checked
                    }
                  } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
            />
          </div>
        </div>

        {/* Zone Zipcodes */}
        <div className="space-y-4">
          <h3 className="text-sm font-medium text-gray-900">Zone Zipcodes</h3>
          <ZipcodeSelectionButton 
            allZipcodes={[
              ...(pickupDelivery.general?.zipcodes || []),
              ...zones.flatMap(zone => zone.zipcodes || [])
            ]}
            zones={zones}
            activeZoneId={zone.id}
            onZipcodeZoneChange={handleZipcodeZoneChange}
          />
        </div>
      </div>

      <ZipcodeCoverageMap
        zipcodes={[
          ...(pickupDelivery.general?.zipcodes || []),
          ...zones.flatMap(zone => zone.zipcodes || [])
        ]}
        zones={zones}
        className="h-full"
        zipBoundariesClient={zipBoundariesClient}  // Add this prop
      />
    </div>
  );

  const renderZoneSchedule = (zone) => {
    // zone.scheduleSameAsDefault => if true, we skip custom logic
    const zoneSchedule = zone.schedule || {};
    const defaultSchedule = pickupDelivery.schedule || {};

    const updateZone = (updates) => {
      const updatedZones = zones.map(z =>
        z.id === zone.id ? { ...z, schedule: { ...z.schedule, ...updates } } : z
      );
      onUpdateLocation({
        ...selectedLocation,
        services: {
          ...selectedLocation.services,
          pickupDelivery: {
            ...pickupDelivery,
            zones: updatedZones
          }
        }
      });
    };

    return (
      <div className="space-y-6">
        {defaultSchedule.customerChooseHours !== undefined && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">Allow Customers to Choose Times</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500">
                Default: {defaultSchedule.customerChooseHours ? "Yes" : "No"}
              </div>
              <Switch
                checked={!!zoneSchedule.customerChooseHours}
                onCheckedChange={(checked) => {
                  updateZone({ customerChooseHours: checked });
                }}
              />
            </div>
          </div>
        )}

        {/* Days Available */}
        <div className="space-y-4">
          <label className="text-sm font-medium text-gray-700">Days Available</label>
          <div className="grid grid-cols-4 gap-2">
            {DAYS_OF_WEEK.map(day => {
              const hasDay = zoneSchedule.days?.includes(day);
              return (
                <motion.button
                  key={day}
                  onClick={() => {
                    const oldDays = zoneSchedule.days || [];
                    const newDays = hasDay
                      ? oldDays.filter(d => d !== day)
                      : [...oldDays, day];
                    updateZone({ days: newDays });
                  }}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={cn(
                    "px-3 py-1.5 text-sm font-medium rounded-md transition-colors",
                    hasDay
                      ? "bg-gray-900 text-white"
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  )}
                >
                  {day}
                </motion.button>
              );
            })}
          </div>
        </div>

        {/* Override Turnaround Time */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Override Turnaround Time</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-sm text-gray-500">
              Default: {defaultSchedule.turnaroundHours}h
            </div>
            <Switch
              checked={zoneSchedule.turnaroundHours !== undefined}
              onCheckedChange={(checked) => {
                updateZone({
                  turnaroundHours: checked
                    ? defaultSchedule.turnaroundHours
                    : undefined
                });
              }}
            />
          </div>
        </div>

        {zoneSchedule.turnaroundHours !== undefined && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-2"
          >
            <label className="text-sm font-medium text-gray-700">
              Zone Turnaround Time (hours)
            </label>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500 bg-gray-50 px-3 py-1.5 rounded-md">
                Default: {defaultSchedule.turnaroundHours}h
              </div>
              <Input
                type="number"
                value={zoneSchedule.turnaroundHours || ''}
                onChange={(e) => {
                  updateZone({ turnaroundHours: parseInt(e.target.value) });
                }}
                className="w-32"
                min="1"
              />
            </div>
          </motion.div>
        )}

        {/* Override Same-Day Service */}
        {defaultSchedule.sameDayService !== undefined && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                Override Same-Day Service
              </span>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500">
                Default: {defaultSchedule.sameDayService ? "Available" : "Not Available"}
              </div>
              <Switch
                checked={zoneSchedule.sameDayService !== undefined}
                onCheckedChange={(checked) => {
                  updateZone({
                    sameDayService: checked
                      ? defaultSchedule.sameDayService
                      : undefined,
                    sameDayCutoff: checked
                      ? defaultSchedule.sameDayCutoff
                      : undefined
                  });
                }}
              />
            </div>
          </div>
        )}

        {defaultSchedule.sameDayService && zoneSchedule.sameDayService !== undefined && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-4"
          >
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <span className="text-sm font-medium text-gray-700">
                Same-Day Service Available
              </span>
              <Switch
                checked={zoneSchedule.sameDayService}
                onCheckedChange={(checked) => {
                  updateZone({ sameDayService: checked });
                }}
              />
            </div>

            {zoneSchedule.sameDayService && (
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Zone Same-Day Cutoff Time
                </label>
                <div className="flex items-center gap-4">
                  <div className="text-sm text-gray-500">
                    Default: {formatTimeDisplay(defaultSchedule.sameDayCutoff)}
                  </div>
                  <Select
                    value={zoneSchedule.sameDayCutoff || ''}
                    onValueChange={(value) => {
                      updateZone({ sameDayCutoff: value });
                    }}
                  >
                    <SelectTrigger className="w-40">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {TIME_OPTIONS.map((time) => (
                        <SelectItem key={time.value} value={time.value}>
                          {time.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </div>
    );
  };

  const renderZonePricing = (zone) => {
    // If zone.pricingSameAsDefault is true, we skip custom logic
    const zonePricing = zone.pricing || {};
    const defaultPricing = pickupDelivery.pricing || {};
    const defaultSchedule = pickupDelivery.schedule || {};  

    const updateZone = (updates) => {
      const updatedZones = zones.map(z =>
        z.id === zone.id ? { ...z, pricing: { ...z.pricing, ...updates } } : z
      );
      onUpdateLocation({
        ...selectedLocation,
        services: {
          ...selectedLocation.services,
          pickupDelivery: {
            ...pickupDelivery,
            zones: updatedZones
          }
        }
      });
    };

    return (
      <div className="space-y-6">
        {/* Override Base Price */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Override Base Price</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-sm text-gray-500">
              Default: ${defaultPricing.regularPrice}/lb
            </div>
            <Switch
              checked={zonePricing.regularPrice !== undefined}
              onCheckedChange={(checked) => {
                updateZone({
                  regularPrice: checked
                    ? defaultPricing.regularPrice
                    : undefined
                });
              }}
            />
          </div>
        </div>

        {zonePricing.regularPrice !== undefined && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-2"
          >
            <label className="text-sm font-medium text-gray-700">Zone Price (per lb)</label>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                value={zonePricing.regularPrice || 0}
                onChange={(e) => {
                  updateZone({ regularPrice: parseFloat(e.target.value) || 0 });
                }}
                className="w-32"
                min="0"
                step="0.01"
              />
            </div>
          </motion.div>
        )}

        {/* Same-Day Price */}
        {defaultSchedule.sameDayService && zone.schedule?.sameDayService && (
          <>
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-3">
                <DollarSign className="w-5 h-5 text-gray-400" />
                <span className="text-sm font-medium text-gray-700">
                  Override Same-Day Price
                </span>
              </div>
              <div className="flex items-center gap-4">
                <div className="text-sm text-gray-500">
                  Default: ${defaultPricing.sameDayPrice}/lb
                </div>
                <Switch
                  checked={zonePricing.sameDayPrice !== undefined}
                  onCheckedChange={(checked) => {
                    updateZone({
                      sameDayPrice: checked
                        ? defaultPricing.sameDayPrice
                        : undefined
                    });
                  }}
                />
              </div>
            </div>

            {zonePricing.sameDayPrice !== undefined && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="space-y-2"
              >
                <label className="text-sm font-medium text-gray-700">
                  Zone Same-Day Price (per lb)
                </label>
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                  <Input
                    type="number"
                    value={zonePricing.sameDayPrice || 0}
                    onChange={(e) => {
                      updateZone({ sameDayPrice: parseFloat(e.target.value) || 0 });
                    }}
                    className="w-32"
                    min="0"
                    step="0.01"
                  />
                </div>
              </motion.div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderZoneOtherInfo = (zone) => {
    return (
      <div className="space-y-6">
        {/* Sub-tabs for Zone Other Info */}
        <div className="flex items-center gap-3">
          <TabButton
            active={otherInfoTab === 'additional'}
            icon={Info}
            label="Additional Info"
            onClick={() => setOtherInfoTab('additional')}
          />
          <TabButton
            active={otherInfoTab === 'faqs'}
            icon={HelpCircle}
            label="FAQs"
            onClick={() => setOtherInfoTab('faqs')}
          />
        </div>

        {/* Content based on selected sub-tab */}
        <AnimatePresence mode="wait">
          <motion.div
            key={otherInfoTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {otherInfoTab === 'additional' && (
              <OtherInfoPanel
                value={zone.otherInfo || []}
                onChange={(newValue) => {
                  const updatedZones = zones.map(z =>
                    z.id === zone.id ? { ...z, otherInfo: newValue } : z
                  );
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        zones: updatedZones
                      }
                    }
                  });
                }}
                title={`Additional ${zone.name || `Zone ${zone.id}`} Information`}
              />
            )}

            {otherInfoTab === 'faqs' && (
              <FAQPanel
                faqs={zone.faqs || []}
                onChange={(newFaqs) => {
                  const updatedZones = zones.map(z =>
                    z.id === zone.id ? { ...z, faqs: newFaqs } : z
                  );
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        zones: updatedZones
                      }
                    }
                  });
                }}
                title={`${zone.name || `Zone ${zone.id}`} FAQs`}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // Final return
  // -------------------------------------------------------------------
  return (
    <>
    <div className="space-y-6">
      {/* Zone Tabs */}
      <div className="flex items-center gap-2 overflow-x-auto pb-2">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="flex items-center gap-2"
        >
          <ZoneTab
            isDefault
            isActive={activeZoneId === 'default'}
            onSelect={() => setActiveZoneId('default')}
          />

          {zones.map((zone) => {
            // Force numeric zone ID
            const numericZoneId = typeof zone.id === 'string' ? parseInt(zone.id, 10) : zone.id;
            const zoneColor = getZoneColor(numericZoneId);
            return (
              <ZoneTab
                key={zone.id}
                zone={zone}
                isActive={activeZoneId === zone.id}
                onSelect={() => setActiveZoneId(zone.id)}
                onDelete={handleDeleteZone}
                style={{
                  '--zone-color': zoneColor,
                  '--zone-bg-color': getBgColor(zoneColor)
                }}
              />
            );
          })}

          <motion.button
            onClick={handleAddZone}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="p-2 rounded-md hover:bg-gray-100"
          >
            <Plus className="w-4 h-4 text-gray-600" />
          </motion.button>
        </motion.div>
      </div>

      {/* Default Tab */}
      {activeZoneId === 'default' && (
        <div className="space-y-6">
          <div className="flex items-center gap-3">
            {['general', 'schedule', 'pricing', 'instructions', 'otherInfo'].map((tab) => {
              const Icon = (
                tab === 'general'   ? Settings :
                tab === 'schedule'  ? Calendar :
                tab === 'pricing'   ? DollarSign :
                tab === 'instructions' ? Truck :
                Info
              );
              const label = tab === 'otherInfo' ? 'Other Info' : 
                           tab.charAt(0).toUpperCase() + tab.slice(1);
              return (
                <TabButton
                  key={tab}
                  active={activeTab === tab}
                  icon={Icon}
                  label={label}
                  onClick={() => setActiveTab(tab)}
                />
              );
            })}
            {pickupDelivery.general?.services?.dryCleaning && (
              <TabButton
                active={activeTab === 'dryCleaning'}
                icon={Sparkles}
                label="Dry Cleaning"
                onClick={() => setActiveTab('dryCleaning')}
              />
            )}
          </div>

          <div className="bg-white rounded-lg border border-gray-200 p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'general' && renderDefaultGeneral()}
                {activeTab === 'schedule' && renderDefaultSchedule()}
                {activeTab === 'pricing' && renderDefaultPricing()}
                {activeTab === 'instructions' && renderDefaultInstructions()}
                {activeTab === 'dryCleaning' && renderDryCleaningContent()}
                {activeTab === 'otherInfo' && renderDefaultOtherInfo()}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}

      {/* Zone-specific Tab */}
      {activeZoneId !== 'default' && (
        <div className="space-y-6">
          <div className="flex items-center gap-3">
            <TabButton
              active={activeTab === 'general'}
              icon={Settings}
              label="General"
              onClick={() => setActiveTab('general')}
            />
            {/* Show dry cleaning tab if this zone has it enabled */}
            {zones.find(z => z.id === activeZoneId)?.services?.dryCleaning && (
              <TabButton
                active={activeTab === 'dryCleaning'}
                icon={Sparkles}
                label="Dry Cleaning"
                onClick={() => setActiveTab('dryCleaning')}
              />
            )}
            {!zones.find(z => z.id === activeZoneId)?.scheduleSameAsDefault && (
              <TabButton
                active={activeTab === 'schedule'}
                icon={Calendar}
                label="Schedule"
                onClick={() => setActiveTab('schedule')}
              />
            )}
            {!zones.find(z => z.id === activeZoneId)?.pricingSameAsDefault && (
              <TabButton
                active={activeTab === 'pricing'}
                icon={DollarSign}
                label="Pricing"
                onClick={() => setActiveTab('pricing')}
              />
            )}
          </div>

          <div className="bg-white rounded-lg border border-gray-200 p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'general' && renderZoneGeneral(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'schedule' && renderZoneSchedule(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'pricing' && renderZonePricing(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'dryCleaning' && renderDryCleaningContent()}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}
    </div>
    <ItemPricingModal
      isOpen={showDryCleaningModal}
      onClose={() => setShowDryCleaningModal(false)}
      title="Dry Cleaning Item Pricing"
      items={pickupDelivery.dryCleaning?.items || []}
      onSave={handleSaveDryCleaningItems}
    />
    </>
  );
};

export default PUDServicePanel;