import React, { useState, useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { 
  ZONE_COLORS,
  ZONE_BG_COLORS,
  COLOR_PALETTE, 
  DEFAULT_COLOR,
  DEFAULT_BG_COLOR,
  getBgColor ,
  getZoneColor
} from '../../../utils/zoneColors';

/**
 * Palettes for dynamic color assignment:
 * - COLOR_PALETTE for “foreground” (border/text)
 * - BG_COLOR_PALETTE for “background” (button background)
 */


/**
 * Button + Modal exports
 */
export const ZipcodeSelectionButton = ({
  allZipcodes = [],
  zones = [],
  activeZoneId,
  onZipcodeZoneChange,
  buttonText = "Choose Zipcodes for Zone",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="outline"
        onClick={() => setIsModalOpen(true)}
        className="w-full flex items-center justify-center gap-2"
      >
        <span>{buttonText}</span>
      </Button>

      <ZipcodeSelectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        allZipcodes={allZipcodes}
        zones={zones}
        activeZoneId={activeZoneId}
        onZipcodeZoneChange={onZipcodeZoneChange}
      />
    </>
  );
};

/**
 * Main Modal component
 */
const ZipcodeSelectionModal = ({
  isOpen,
  onClose,
  allZipcodes = [],
  zones = [],
  activeZoneId,
  onZipcodeZoneChange,
}) => {
  const [selectedZoneId, setSelectedZoneId] = useState(
    activeZoneId ? String(activeZoneId) : 'default'
  );

  /**
   * Refs for storing dynamically assigned colors for each zone ID
   */
  const zoneFgColorsRef = useRef({});
  const zoneBgColorsRef = useRef({});
  const colorIndexRef = useRef(0);

  /**
   * Dynamically assign (or retrieve) a color pair for a given zoneId
   */
  const getDynamicColorsForZoneId = (zoneId) => {
    const color = getZoneColor(zoneId);  // Use the shared function
    return {
      fg: color,
      bg: getBgColor(color),
    };
  };

  /**
   * Get the zone ID for a given zipcode
   */
  const getZipcodeZone = (zipcode) => {
    const zone = zones.find((z) => (z.zipcodes || []).includes(zipcode));
    return zone ? String(zone.id) : 'default';
  };

  /**
   * Memoize grouping of all zipcodes by zone
   */
  const zipcodesByZone = useMemo(() => {
    const grouped = { default: [] };
    // init each zone key
    zones.forEach((zone) => {
      grouped[String(zone.id)] = [];
    });
    // place each zipcode into the correct zone array
    allZipcodes.forEach((zipcode) => {
      const zoneId = getZipcodeZone(zipcode);
      grouped[zoneId].push(zipcode);
    });
    // Sort zipcodes within each zone
    Object.keys(grouped).forEach((zoneId) => {
      grouped[zoneId].sort((a, b) => parseInt(a) - parseInt(b));
    });
    return grouped;
  }, [allZipcodes, zones]);

  /**
   * On clicking a zipcode, re-assign it to the currently selectedZoneId
   */
  const handleZipcodeClick = (zipcode) => {
    const currentZoneId = getZipcodeZone(zipcode);
    if (currentZoneId === selectedZoneId) {
      // No change needed
      return;
    }
    onZipcodeZoneChange(zipcode, selectedZoneId, currentZoneId);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Choose Zipcodes for Zone</DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          {/* Zone Selector Dropdown */}
          <div className="w-full">
            <Select
              value={selectedZoneId}
              onValueChange={setSelectedZoneId}
            >
              <SelectTrigger className="w-full">
                <SelectValue>
                  {(() => {
                    const { fg } = getDynamicColorsForZoneId(selectedZoneId);
                    const zoneName =
                      selectedZoneId === 'default'
                        ? 'Default'
                        : zones.find((z) => String(z.id) === selectedZoneId)?.name ||
                          `Zone ${selectedZoneId}`;
                    return (
                      <div className="flex items-center gap-2">
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: fg }}
                        />
                        <span>{zoneName}</span>
                      </div>
                    );
                  })()}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {/* Default option */}
                <SelectItem value="default">
                  {(() => {
                    const { fg } = getDynamicColorsForZoneId('default');
                    return (
                      <div className="flex items-center gap-2">
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: fg }}
                        />
                        <span>Default</span>
                      </div>
                    );
                  })()}
                </SelectItem>

                {/* Custom zone options */}
                {zones.map((zone) => {
                  const zoneId = String(zone.id);
                  const { fg } = getDynamicColorsForZoneId(zoneId);
                  return (
                    <SelectItem key={zone.id} value={zoneId}>
                      <div className="flex items-center gap-2">
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: fg }}
                        />
                        <span>{zone.name || `Zone ${zoneId}`}</span>
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>

          {/* Zipcode Groups */}
          <div className="space-y-4 max-h-[400px] overflow-y-auto">
            {/* Default Group */}
            <div key="default" className="space-y-2">
              <h3 className="text-sm font-medium flex items-center gap-2">
                {(() => {
                  const { fg } = getDynamicColorsForZoneId('default');
                  return (
                    <>
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: fg }}
                      />
                      <span>Default</span>
                    </>
                  );
                })()}
              </h3>
              <div className="grid grid-cols-4 gap-2">
                {(zipcodesByZone.default || []).map((zipcode) => {
                  const { fg, bg } = getDynamicColorsForZoneId('default');
                  return (
                    <motion.button
                      key={`default-${zipcode}`}
                      onClick={() => handleZipcodeClick(zipcode)}
                      className="px-3 py-2 text-sm font-medium rounded-md border"
                      style={{
                        backgroundColor: bg,
                        borderColor: fg,
                        color: fg,
                      }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {zipcode}
                    </motion.button>
                  );
                })}
              </div>
            </div>

            {/* Dynamic zone groups */}
            {zones.map((zone) => {
              const zoneId = String(zone.id);
              const zoneZipcodes = zipcodesByZone[zoneId] || [];
              const { fg, bg } = getDynamicColorsForZoneId(zoneId);

              return (
                <div key={zoneId} className="space-y-2">
                  <h3 className="text-sm font-medium flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: fg }}
                    />
                    {zone.name || `Zone ${zoneId}`}
                  </h3>
                  <div className="grid grid-cols-4 gap-2">
                    {zoneZipcodes.map((zipcode) => (
                      <motion.button
                        key={zipcode}
                        onClick={() => handleZipcodeClick(zipcode)}
                        className="px-3 py-2 text-sm font-medium rounded-md border"
                        style={{
                          backgroundColor: bg,
                          borderColor: fg,
                          color: fg,
                        }}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        {zipcode}
                      </motion.button>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { ZipcodeSelectionModal };
