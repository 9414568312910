import React from 'react';
import { cn } from '../../utils';

export function ScrollArea({ className = "", children, ...props }) {
  return (
    <div
      className={cn(
        "relative overflow-auto max-h-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function ScrollBar({ orientation = "vertical", className = "", ...props }) {
  return (
    <div
      className={cn(
        "flex select-none touch-none",
        orientation === "vertical" ? "h-full w-2.5" : "h-2.5 flex-col",
        className
      )}
      {...props}
    />
  );
}