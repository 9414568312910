// src/App.js
import React, { useEffect, Suspense  } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useBusinessType, BusinessProvider } from './context/BusinessContext';
import ProtectedRoute from './components/ProtectedRoute';
import { isRouteAccessible } from './config/routeAccess';
import { useLoadScript } from '@react-google-maps/api';
import { MAPS_CONFIG } from './config/maps';
import { Toaster } from "./components/ui/Toaster";
import { getSupabaseClient } from './supabaseClient';
import "./styles/styles.css";
import SideNav from "./components/SideNav";
import OnboardingRoute, { getOnboardingRedirectPath } from './components/signup/OnboardingRoutes.js';
import BusinessDetailsStep from './components/signup/business-details/BusinessDetailsStep';
import 'mapbox-gl/dist/mapbox-gl.css';

// Page imports
const Login = React.lazy(() => import("./pages/Login"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Dashboard = React.lazy(() => import("./pages/LaundryDashboard"));
const LeadsManagement = React.lazy(() => import("./pages/LeadsManagement"));
const LeadDetails = React.lazy(() => import("./components/contacts/LeadDetails"));
const BookingsPage = React.lazy(() => import("./pages/BookingsPage"));
const ToDoPage = React.lazy(() => import("./pages/ToDoPage"));
const QnAManagement = React.lazy(() => import("./pages/QnAManagement"));
const SOPManagement = React.lazy(() => import("./pages/SOPManagement"));
const Reports = React.lazy(() => import("./pages/Reports"));
const Settings = React.lazy(() => import("./pages/Settings"));
const ServicesPricingPage = React.lazy(() => import("./pages/ServicesPricingPage"));
const CallSettings = React.lazy(() => import("./pages/CallSettings"));
const LocationsManagement = React.lazy(() => import("./pages/LocationsManagement"));
const EmailPreferencesManager = React.lazy(() => import("./pages/EmailPreferencesManager"));
const AccountSetupFlow = React.lazy(() => import("./pages/onboarding/AccountSetupFlow"));
const OnboardingLayout = React.lazy(() => import("./pages/onboarding/OnboardingLayout"));
const Integrations = React.lazy(() => import("./pages/Integrations"));
const CallsListPage = React.lazy(() => import("./pages/CallsListPage"));
const CallDetailsPage = React.lazy(() => import("./components/calls/CallDetailsPage"));
const LaundrySignup = React.lazy(() => import("./pages/LaundrySignup"));
const UnifiedLocationsQnA = React.lazy(() => import("./pages/UnifiedLocationsQnA"));
const IntroPage = React.lazy(() => import("./pages/IntroPage"));
const NotificationsPage = React.lazy(() => import("./pages/NotificationsPage"));
const PhoneIntegrationPage = React.lazy(() => import("./pages/PhoneIntegration"));
const Payment = React.lazy(() => import("./pages/Payment"));




const PageLoader = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

// Coming Soon Component
const ComingSoon = ({ title = "Coming Soon", description = "This feature is currently under development." }) => (
  <div className="min-h-screen bg-gray-50 p-8">
    <div className="max-w-2xl mx-auto mt-20">
      <div className="bg-white rounded-lg p-8 shadow-lg border border-gray-100">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-4">{title}</h1>
        <p className="text-center text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

// In App.js AuthProvider
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);
  const location = useLocation();

  useEffect(() => {
    const publicRoutes = ['/login', '/signup', '/join'];
    const onboardingRoutes = ['/onboarding/business'];

    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();

        if (!session && !publicRoutes.includes(location.pathname)) {
          navigate('/login');
          return;
        }

        if (session) {
          const { data: onboarding, error: onboardingError } = await supabase
            .from('onboarding_data')
            .select('current_step')
            .eq('account_id', session.user.id)
            .single();

          if (onboardingError && onboardingError.code === '42P01') {
            if (!onboardingRoutes.includes(location.pathname)) {
              navigate('/onboarding/intro');
            }
            return;
          }

          if (onboarding?.current_step === 'not_started') {
            // Let Login.js handle the first-time login case
            return;
          }

          if (!onboarding?.current_step || onboarding.current_step !== 'complete') {
            if (!onboardingRoutes.includes(location.pathname)) {
              const correctPath = getOnboardingRedirectPath(onboarding?.current_step);
              navigate(correctPath);
            }
            return;
          }

          if (onboarding.current_step === 'complete' && 
              !publicRoutes.includes(location.pathname) && 
              !isRouteAccessible(businessType, location.pathname)) {
            navigate('/home');
          }
        }
      } catch (error) {
        console.error('Session check error:', error);
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT') {
        navigate('/login');
      } else if (event === 'SIGNED_IN' && session) {
        // Let checkSession handle the routing
        checkSession();
      }
    });

    return () => subscription?.unsubscribe();
  }, [navigate, supabase, location.pathname, businessType]);

  return children;
};

const App = () => {
  const location = useLocation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAPS_CONFIG.apiKey,
    libraries: MAPS_CONFIG.libraries,
  });

  const noNavRoutes = [
    '/login', 
    '/signup', 
    '/setup', 
    '/onboarding', 
    '/onboarding/phone', 
    '/onboarding/pathway', 
    '/onboarding/details',
    '/join',
    '/onboarding/intro',
    '/onboarding/business',
    '/onboarding/workflows',
    '/onboarding/notifications',
    '/onboarding/integration',
    '/onboarding/payment'
  ];

  const isDashboard = location.pathname === '/home';
  const shouldShowNav = !noNavRoutes.includes(location.pathname);

  if (!isLoaded) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <BusinessProvider>
      <AuthProvider>
        <div className="flex">
          {shouldShowNav && <SideNav forceExpanded={!isDashboard} />}
          <main className={`flex-1 min-h-screen overflow-x-hidden ${shouldShowNav && !location.pathname.includes('/home') ? 'ml-16 md:ml-64' : ''}`}>
            <Suspense fallback={
              <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            }>
              <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/join" element={<LaundrySignup />} />

                {/* Onboarding Routes */}
                <Route path="/onboarding" element={<OnboardingRoute />}>
                  <Route index element={<Navigate to="/onboarding/intro" />} />
                  <Route path="intro" element={
                    <div className="min-h-screen bg-gray-50">
                      <IntroPage isOnboarding={true} />
                    </div>
                  } />
                  <Route path="business" element={
                    <div className="min-h-screen bg-gray-50">
                      <UnifiedLocationsQnA isOnboarding={true} />
                    </div>
                  } />
                  <Route path="workflows" element={
                    <div className="min-h-screen bg-gray-50">
                      <SOPManagement isOnboarding={true} />
                    </div>
                  } />
                  <Route path="notifications" element={
                    <div className="min-h-screen bg-gray-50">
                      <NotificationsPage />
                    </div>
                  } />
                  <Route path="integration" element={
                    <div className="min-h-screen bg-gray-50">
                      <PhoneIntegrationPage />
                    </div>
                  } />
                  <Route path="payment" element={
                    <div className="min-h-screen bg-gray-50">
                      <Payment />
                    </div>
                  } />
                </Route>

                {/* Protected Routes */}
                <Route path="/home" element={
                  <ProtectedRoute path="/home">
                    <Dashboard />
                  </ProtectedRoute>
                } />

                <Route path="/activity/contacts" element={
                  <ProtectedRoute path="/contacts">
                    <LeadsManagement />
                  </ProtectedRoute>
                } />

                <Route path="/activity/contacts/:id" element={
                  <ProtectedRoute path="/contacts/:id">
                    <LeadDetails />
                  </ProtectedRoute>
                } />

                <Route path="/activity/bookings" element={
                  <ProtectedRoute path="/leads/bookings">
                    <BookingsPage />
                  </ProtectedRoute>
                } />

                <Route path="/activity/tasks" element={
                  <ProtectedRoute path="/tasks">
                    <ToDoPage />
                  </ProtectedRoute>
                } />

                <Route path="/business/knowledge" element={
                  <ProtectedRoute path="/business/knowledge">
                    <UnifiedLocationsQnA />
                  </ProtectedRoute>
                } />

                <Route path="/business/triggers" element={
                  <ProtectedRoute path="/business/triggers">
                    <SOPManagement />
                  </ProtectedRoute>
                } />

                <Route path="/business/faqs" element={
                  <ProtectedRoute path="/business/faqs">
                    <QnAManagement />
                  </ProtectedRoute>
                } />

                <Route path="/business/services" element={
                  <ProtectedRoute path="/business/services">
                    <ServicesPricingPage />
                  </ProtectedRoute>
                } />

                <Route path="/business/locations" element={
                  <ProtectedRoute path="/business/locations">
                    <LocationsManagement />
                  </ProtectedRoute>
                } />

                <Route path="/caller/integrations" element={
                  <ProtectedRoute path="/caller/integrations">
                    <Integrations />
                  </ProtectedRoute>
                } />

                <Route path="/caller/voice" element={
                  <ProtectedRoute path="/caller/voice">
                    <CallSettings />
                  </ProtectedRoute>
                } />

                <Route path="/caller/notifications" element={
                  <ProtectedRoute path="/caller/notifications">
                    <EmailPreferencesManager />
                  </ProtectedRoute>
                } />

                <Route path="/reports/insights" element={
                  <ProtectedRoute path="/reports/insights">
                    <Reports />
                  </ProtectedRoute>
                } />

                <Route path="/account/general" element={
                  <ProtectedRoute path="/account/general">
                    <Settings />
                  </ProtectedRoute>
                } />

                <Route path="/account/payment" element={
                  <ProtectedRoute path="/account/payment">
                    <ComingSoon 
                      title="Payment Settings" 
                      description="Manage your billing and subscription."
                    />
                  </ProtectedRoute>
                } />

                <Route path="/activity/calls" element={
                  <ProtectedRoute path="/calls">
                    <CallsListPage />
                  </ProtectedRoute>
                } />

                <Route 
                  path="/activity/calls/:id" 
                  element={
                    <ProtectedRoute>
                      <CallDetailsPage />
                    </ProtectedRoute>
                  } 
                />

                {/* Default Route */}
                <Route path="/" element={<Navigate to="/home" replace />} />

                {/* Catch-All Route for Undefined Paths */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </main>
          <Toaster />
        </div>
      </AuthProvider>
    </BusinessProvider>
  );
};

export default App;