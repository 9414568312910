import React from 'react';
import { cn } from '../../utils';

export function Switch({ 
  className = "", 
  checked, 
  onChange, 
  onCheckedChange,
  ...props 
}) {
  const handleChange = () => {
    const newValue = !checked;
    onChange?.(newValue);
    onCheckedChange?.(newValue);
  };

  return (
    <button
      role="switch"
      aria-checked={checked}
      onClick={handleChange}
      className={cn(
        "relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full transition-colors",
        "border-2 border-transparent",
        checked ? "bg-gray-900" : "bg-gray-200",
        className
      )}
      {...props}
    >
      <span
        className={cn(
          "pointer-events-none block h-4 w-4 rounded-full bg-white shadow-lg ring-0 transition-transform",
          checked ? "translate-x-4" : "translate-x-0"
        )}
      />
    </button>
  );
}