import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Trash2, HelpCircle, MessageCircle, Pencil, X } from 'lucide-react';
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/text-area";

const modalVariants = {
  backdrop: {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1]
      }
    },
    exit: { 
      opacity: 0,
      transition: { 
        duration: 0.2,
        ease: [0.16, 1, 0.3, 1]
      }
    }
  },
  content: {
    hidden: { opacity: 0, y: -20, scale: 0.98 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1]
      }
    },
    exit: {
      opacity: 0,
      y: 20,
      scale: 0.98,
      transition: {
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1]
      }
    }
  }
};

const faqItemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: {
    opacity: 0,
    x: -10,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div 
            className="fixed inset-0 z-[10000] bg-black/50" 
            variants={modalVariants.backdrop}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          <div className="fixed inset-0 z-[10001] overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4">
              <motion.div 
                className="relative w-[800px] bg-white rounded-lg shadow-lg mt-[5vh] overflow-hidden"
                variants={modalVariants.content}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                {children}
              </motion.div>
            </div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

const FAQModal = ({ isOpen, onClose, onSubmit, initialData = null }) => {
  const [question, setQuestion] = useState(initialData?.["Conversation History"] || '');
  const [answer, setAnswer] = useState(initialData?.["Assistant Response"] || '');

  const handleSubmit = () => {
    if (question.trim() && answer.trim()) {
      onSubmit({
        "Conversation History": question.trim(),
        "Assistant Response": answer.trim()
      });
      setQuestion('');
      setAnswer('');
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="border-b border-gray-200 px-6 py-4 flex justify-between items-center">
        <h2 className="text-lg font-semibold text-gray-900">
          {initialData ? 'Edit FAQ' : 'Add New FAQ'}
        </h2>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClose}
          className="p-1 hover:bg-gray-100 rounded-full transition-colors"
        >
          <X className="w-5 h-5 text-gray-500" />
        </motion.button>
      </div>

      <div className="px-6 py-6 space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <HelpCircle className="w-4 h-4 text-gray-500" />
            <label className="text-sm font-medium text-gray-700">Question</label>
          </div>
          <Input
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter the question..."
            className="w-full bg-gray-50 border-gray-200 focus:border-gray-300 focus:ring-gray-200"
          />
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <MessageCircle className="w-4 h-4 text-gray-500" />
            <label className="text-sm font-medium text-gray-700">Answer</label>
          </div>
          <Textarea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Enter the answer..."
            className="w-full min-h-[200px] resize-none bg-gray-50 border-gray-200 focus:border-gray-300 focus:ring-gray-200"
          />
        </div>
      </div>

      <div className="px-6 py-4 border-t border-gray-200 flex justify-end gap-3">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 rounded-md transition-colors"
        >
          Cancel
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleSubmit}
          disabled={!question.trim() || !answer.trim()}
          className="px-4 py-2 text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 rounded-md disabled:opacity-50 disabled:hover:bg-gray-900 transition-colors"
        >
          {initialData ? 'Save Changes' : 'Add FAQ'}
        </motion.button>
      </div>
    </Modal>
  );
};

const FAQPanel = ({ faqs = [], onChange, title = "Frequently Asked Questions" }) => {
  const [showModal, setShowModal] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleAddFaq = (newFaq) => {
    onChange([...faqs, newFaq]);
  };

  const handleEditFaq = (updatedFaq) => {
    const newFaqs = [...faqs];
    newFaqs[editingIndex] = updatedFaq;
    onChange(newFaqs);
    setEditingFaq(null);
    setEditingIndex(null);
  };

  const handleDeleteFaq = (index) => {
    onChange(faqs.filter((_, i) => i !== index));
  };

  const handleEdit = (faq, index) => {
    setEditingFaq(faq);
    setEditingIndex(index);
    setShowModal(true);
  };

  return (
    <motion.div 
      className="space-y-4"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, ease: [0.16, 1, 0.3, 1] }}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium text-gray-700">{title}</h3>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => {
            setEditingFaq(null);
            setEditingIndex(null);
            setShowModal(true);
          }}
          className="flex items-center gap-2 px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800 transition-colors"
        >
          <Plus className="w-4 h-4" />
          <span className="text-sm font-medium">Add FAQ</span>
        </motion.button>
      </div>

      <div className="space-y-3">
        <AnimatePresence mode="popLayout">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              variants={faqItemVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              layout
              className="p-4 bg-gray-50 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors group"
            >
              <div className="flex items-start justify-between gap-4">
                <div className="flex-1 space-y-3">
                  <div className="flex items-start gap-2">
                    <HelpCircle className="w-4 h-4 mt-1 text-gray-500 flex-shrink-0" />
                    <p className="text-sm font-medium text-gray-900">
                      {faq["Conversation History"]}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <MessageCircle className="w-4 h-4 mt-1 text-gray-500 flex-shrink-0" />
                    <p className="text-sm text-gray-700">
                      {faq["Assistant Response"]}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <motion.button
                    onClick={() => handleEdit(faq, index)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="p-1.5 text-gray-500 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
                  >
                    <Pencil className="w-4 h-4" />
                  </motion.button>
                  <motion.button
                    onClick={() => handleDeleteFaq(index)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="p-1.5 text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors"
                  >
                    <Trash2 className="w-4 h-4" />
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}

          {faqs.length === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4, ease: [0.16, 1, 0.3, 1] }}
              className="p-8 text-center bg-gray-50 rounded-lg border border-gray-200"
            >
              <p className="text-sm text-gray-500">
                No FAQs added yet. Click the button above to add one.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <FAQModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingFaq(null);
          setEditingIndex(null);
        }}
        onSubmit={editingFaq ? handleEditFaq : handleAddFaq}
        initialData={editingFaq}
      />
    </motion.div>
  );
};

export default FAQPanel;