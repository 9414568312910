import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getSupabaseClient, BUSINESS_TYPES } from '../supabaseClient';
import { useBusinessType } from '../context/BusinessContext';
import {
  Home,
  Users,
  Settings,
  Phone,
  FileText,
  ChevronLeft,
  ChevronRight,
  LogOut,
  ChevronDown,
  MessageSquare,
  Building2,
} from 'lucide-react';
import { isRouteAccessible } from '../config/routeAccess';
import { motion, AnimatePresence } from 'framer-motion';

const SideNav = ({ forceExpanded = false }) => {
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);

  const [isExpanded, setIsExpanded] = useState(forceExpanded);
  const [isFullyExpanded, setIsFullyExpanded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState('');
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const profileMenuRef = useRef(null);
  const hideMenuTimer = useRef(null);

  // Define base navigation structure
  const baseNavItems = [
    {
      icon: Home,
      label: 'Home',
      path: '/home',
    },
    {
      icon: Users,
      label: 'Activity',
      submenu: [
        { label: 'Calls', path: '/activity/calls' },
        { label: 'Bookings', path: '/activity/bookings' },
        { label: 'Tasks', path: '/activity/tasks' },
        { label: 'Contacts', path: '/activity/contacts' }, // Updated path
      ],
    },
    {
      icon: Building2,
      label: 'Business Settings',
      submenu: [
        { label: 'Knowledge', path: '/business/knowledge' },
        { label: 'FAQs', path: '/business/faqs' },
        { label: 'Services', path: '/business/services' },
        { label: 'Locations', path: '/business/locations' },
        { label: 'Triggers', path: '/business/triggers' },
      ],
    },
    {
      icon: Phone,
      label: 'Caller Settings',
      submenu: [
        { label: 'Voice', path: '/caller/voice' },
        { label: 'Integrations', path: '/caller/integrations' },
        { label: 'Email Notifications', path: '/caller/notifications' },
      ],
    },
    
    //{
    //  icon: FileText,
    //  label: 'Reports',
    //  submenu: [{ label: 'Insights', path: '/reports/insights' }],
    //},
  ];

  // Filter nav items based on business type and route access
  const navItems = baseNavItems
    .map((item) => {
      if (item.submenu) {
        return {
          ...item,
          submenu: item.submenu.filter((subItem) =>
            isRouteAccessible(businessType, subItem.path)
          ),
        };
      }
      return item;
    })
    .filter((item) =>
      item.path
        ? isRouteAccessible(businessType, item.path)
        : item.submenu?.length > 0 // Only keep submenu items if they have accessible routes
    );

  useEffect(() => {
    fetchUserData();

    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setIsExpanded(forceExpanded);
    setIsFullyExpanded(forceExpanded);
    if (!forceExpanded && !isMobileMenuOpen) {
      setOpenSubmenu('');
    }
  }, [forceExpanded, isMobileMenuOpen]);

  const fetchUserData = async () => {
    try {
      // Get the stored business type
      const storedBusinessType = localStorage.getItem('businessType');
      if (!storedBusinessType) {
        throw new Error('No business type found');
      }

      const supabase = getSupabaseClient(storedBusinessType);

      // First check if we have a session
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (!session) {
        // If no session, redirect to login
        navigate('/login');
        return;
      }

      // Get user data
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError) throw userError;

      if (user) {
        setUserData(user);

        // Get account data
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('*')
          .eq('id', user.id)
          .eq('business_type', storedBusinessType)
          .single();

        if (accountError) throw accountError;
        setAccountData(accountData);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // On any error, clear local storage and redirect to login
      localStorage.removeItem('businessType');
      localStorage.removeItem('supabaseSession');
      navigate('/login');
    }
  };

  const handleLogout = async () => {
    try {
      // First sign out from both supabase instances
      await Promise.all([
        getSupabaseClient(BUSINESS_TYPES.CLEANING).auth.signOut(),
        getSupabaseClient(BUSINESS_TYPES.LAUNDROMAT).auth.signOut(),
      ]);

      // Only after successful signout, clear storage and state
      localStorage.clear();

      // Force a full page refresh to /login
      window.location.replace('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      // Even on error, force a clean logout
      localStorage.clear();
      window.location.replace('/login');
    }
  };

  const getInitials = () => {
    return (
      accountData?.full_name
        ?.split(' ')
        .map((n) => n[0])
        .join('') || 'U'
    );
  };

  const isSubmenuActive = (submenu) => {
    return submenu.some((item) => location.pathname.startsWith(item.path));
  };

  const handleMouseEnterProfile = () => {
    if (hideMenuTimer.current) {
      clearTimeout(hideMenuTimer.current);
      hideMenuTimer.current = null;
    }
    setShowProfileMenu(true);
  };

  const handleMouseLeaveProfile = () => {
    hideMenuTimer.current = setTimeout(() => {
      setShowProfileMenu(false);
    }, 200);
  };

  // Animation variants
  const sideNavVariants = {
    expanded: { width: 256 },
    collapsed: { width: 64 },
  };

  const menuItemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <>
    <motion.div
      initial={false}
      animate={isMobile ? (isMobileMenuOpen ? 'expanded' : 'collapsed') : isExpanded ? 'expanded' : 'collapsed'}
      variants={sideNavVariants}
      onMouseEnter={() => !forceExpanded && !isMobile && setIsExpanded(true)}
      onMouseLeave={() => !forceExpanded && !isMobile && setIsExpanded(false)}
      onClick={() => isMobile && !isMobileMenuOpen && setIsMobileMenuOpen(true)}
      onTransitionEnd={() => {
        setIsFullyExpanded(isExpanded || forceExpanded);
      }}
      className="fixed left-0 top-0 h-screen bg-white shadow-lg transition-all duration-300 ease-in-out z-[9999] flex flex-col"
    >
      {/* Hamburger Menu Button for Mobile */}
      {isMobile && (
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="absolute top-4 left-4 p-2 rounded-md bg-gray-100"
        >
          {/* Add a hamburger icon here (e.g., from Lucide-react) */}
          {/* Example: <Hamburger className="w-6 h-6" /> */}
        </button>
      )}

      {/* Logo Section */}
      <div className="h-16 border-b border-gray-100 flex items-center px-4 overflow-hidden">
        <span className={`text-xl font-semibold text-gray-800 whitespace-nowrap px-3 ${
          isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
        }`}>
          Savant
        </span>
      </div>

      {/* Navigation Items */}
      <div className="flex-1 overflow-y-auto px-2 py-4 space-y-2">
        {navItems.map((item) => (
          <div key={item.label} className="relative">
            {item.submenu ? (
              // Submenu Item
              <div>
                <button
                  onClick={() =>
                    setOpenSubmenu(
                      openSubmenu === item.label ? '' : item.label
                    )
                  }
                  className={`w-full h-9 flex items-center px-3 rounded-lg transition-colors
                    ${
                      isSubmenuActive(item.submenu)
                        ? 'bg-gray-50 text-gray-800'
                        : 'text-gray-600 hover:bg-gray-50'
                    }
                  `}
                >
                  <div className="w-5 h-5">
                    <item.icon className="w-5 h-5" />
                  </div>
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: (isExpanded || isMobileMenuOpen) ? 1 : 0 }}
                    transition={{ duration: 0.2 }}
                    className={`ml-3 whitespace-nowrap truncate ${
                      isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
                    }`}
                  >
                    {item.label}
                  </motion.span>
                  {(isExpanded || isMobileMenuOpen) && (
                    <ChevronDown
                      className={`w-4 h-4 transition-transform ml-auto ${
                        openSubmenu === item.label ? 'rotate-180' : ''
                      }`}
                    />
                  )}
                </button>
                <AnimatePresence>
                  {((isExpanded || isMobileMenuOpen) && openSubmenu === item.label) && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className={`overflow-hidden ${isExpanded || isMobileMenuOpen ? 'ml-8 mt-1' : 'w-0 h-0 p-0'}`}
                    >
                      {item.submenu.map((subItem) => (
                      <NavLink
                        key={subItem.path}
                        to={subItem.path}
                        onClick={() => isMobile && setIsMobileMenuOpen(false)} // added for mobile
                        className={({ isActive }) => `
                          w-full flex items-center px-3 py-2 rounded-lg text-sm
                          ${isActive ? 'bg-gray-50 text-gray-800' : 'text-gray-600 hover:bg-gray-50'}
                        `}
                      >
                        <span className="whitespace-nowrap truncate">
                          {subItem.label}
                        </span>
                      </NavLink>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
            // Regular Item
            <NavLink
              to={item.path}
              onClick={() => isMobile && setIsMobileMenuOpen(false)} // added for mobile
              className={({ isActive }) => `w-full h-9 flex items-center px-3 rounded-lg transition-colors
                ${isActive ? 'bg-gray-50 text-gray-800' : 'text-gray-600 hover:bg-gray-50'}
              `}
            >
              <div className="w-5 h-5">
                <item.icon className="w-5 h-5" />
              </div>
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: (isExpanded || isMobileMenuOpen) ? 1 : 0 }}
                transition={{ duration: 0.2 }}
                className={`ml-3 whitespace-nowrap truncate ${
                  isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
                }`}
              >
                {item.label}
              </motion.span>
            </NavLink>
            )}
          </div>
        ))}
      </div>

      {/* Profile Section */}
      <div 
       className="relative p-4 border-t border-gray-100 bg-white"
       onMouseEnter={handleMouseEnterProfile}
       onMouseLeave={handleMouseLeaveProfile}
      >
       {/* Profile Menu */}
       <motion.div
         ref={profileMenuRef}
         style={{ 
           opacity: (isExpanded || isMobileMenuOpen || forceExpanded) && showProfileMenu ? 1 : 0,
           transform: `translateY(${(isExpanded || isMobileMenuOpen || forceExpanded) && showProfileMenu ? 0 : 10}px)`,
           pointerEvents: (isExpanded || isMobileMenuOpen || forceExpanded) && showProfileMenu ? 'auto' : 'none'
         }}
         transition={{ duration: 0.2 }}
         className="absolute left-0 bottom-full mb-2 w-full bg-white rounded-lg shadow-lg border border-gray-100 z-10"
       >
         <div className="p-2 space-y-1">
           <NavLink
             to="/account/general"
             className={({ isActive }) => `
               flex items-center px-3 py-2 rounded-lg text-sm w-full
               ${isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'}
             `}
           >
             <Settings className="w-4 h-4 flex-shrink-0" />
             <span className="ml-2 truncate">Settings</span>
           </NavLink>
           <button
             onClick={handleLogout}
             className="w-full flex items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg"
           >
             <LogOut className="w-4 h-4 flex-shrink-0" />
             <span className="ml-2 truncate">Logout</span>
           </button>
         </div>
       </motion.div>

       {/* Profile Content */}
       <div className="flex items-center cursor-pointer">
         <div
           className="w-9 h-9 rounded-full bg-gray-50 flex items-center justify-center
                   text-gray-800 font-medium flex-shrink-0"
         >
           {getInitials()}
         </div>
         <motion.div
           initial="hidden"
           animate={(isExpanded || isMobileMenuOpen) ? "visible" : "hidden"}
           variants={{
             visible: { 
               width: 'auto',
               marginLeft: 12,
               opacity: 1,
               transition: {
                 duration: 0.2,
               }
             },
             hidden: { 
               width: 0,
               marginLeft: 0,
               opacity: 0,
               transition: {
                 duration: 0.2,
               }
             }
           }}
           className="overflow-hidden"
         >
           <p className="text-sm font-medium text-gray-700 truncate whitespace-nowrap">
             {accountData?.full_name}
           </p>
           <p className="text-xs text-gray-500 truncate whitespace-nowrap">
             {userData?.email}
           </p>
         </motion.div>
       </div>
      </div>
    </motion.div>

      {/* Mobile Backdrop */}
      {isMobile && isMobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsMobileMenuOpen(false)}
          className="fixed inset-0 bg-black/50 z-[9998]"
        />
      )}
    </>
  );
};

export default SideNav;