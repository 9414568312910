import React, { useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TooltipContext = React.createContext(null);

const TooltipProvider = ({ children }) => {
  return <>{children}</>;
};

const TooltipRoot = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <TooltipContext.Provider value={{ isOpen, setIsOpen }}>
      <div className="relative inline-block">
        {children}
      </div>
    </TooltipContext.Provider>
  );
};

const TooltipTrigger = React.forwardRef(({ className = '', asChild = false, onClick, children, ...props }, ref) => {
  const { setIsOpen } = React.useContext(TooltipContext);
  const Comp = asChild ? 'div' : 'button';

  const handleMouseEnter = useCallback((e) => {
    e.stopPropagation();
    setIsOpen(true);
  }, [setIsOpen]);

  const handleMouseLeave = useCallback((e) => {
    e.stopPropagation();
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Comp
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      className={`${!asChild ? 'p-1.5 text-gray-400 hover:text-gray-600 rounded-full transition-colors' : ''} ${className}`}
      {...props}
    >
      {children}
    </Comp>
  );
});

TooltipTrigger.displayName = 'TooltipTrigger';

const TooltipContent = React.forwardRef(({ 
  className = '',
  side = 'top',
  align = 'center',
  children,
  ...props 
}, ref) => {
  const { isOpen } = React.useContext(TooltipContext);

  const alignments = {
    start: 'left-0',
    center: 'left-1/2 -translate-x-1/2',
    end: 'right-0'
  };

  const positions = {
    top: 'bottom-[calc(100%+8px)]',
    bottom: 'top-[calc(100%+8px)]',
    left: 'right-[calc(100%+8px)]',
    right: 'left-[calc(100%+8px)]'
  };

  const arrows = {
    top: {
      className: 'border-t-[6px] border-x-[6px] border-x-transparent',
      placement: 'bottom-[-5px] border-t-gray-900',
      alignment: {
        start: 'left-3',
        center: 'left-1/2 -translate-x-1/2',
        end: 'right-3'
      }
    },
    bottom: {
      className: 'border-b-[6px] border-x-[6px] border-x-transparent',
      placement: 'top-[-5px] border-b-gray-900',
      alignment: {
        start: 'left-3',
        center: 'left-1/2 -translate-x-1/2',
        end: 'right-3'
      }
    },
    left: {
      className: 'border-l-[6px] border-y-[6px] border-y-transparent',
      placement: 'right-[-5px] border-l-gray-900',
      alignment: {
        start: 'top-3',
        center: 'top-1/2 -translate-y-1/2',
        end: 'bottom-3'
      }
    },
    right: {
      className: 'border-r-[6px] border-y-[6px] border-y-transparent',
      placement: 'left-[-5px] border-r-gray-900',
      alignment: {
        start: 'top-3',
        center: 'top-1/2 -translate-y-1/2',
        end: 'bottom-3'
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={ref}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{
            duration: 0.1,
            ease: 'easeOut'
          }}
          style={{ 
            position: 'absolute',
            minWidth: 'max-content'
          }}
          className={`
            ${positions[side]} 
            ${alignments[align]} 
            z-[9999]
            px-2.5 
            py-1.5 
            bg-gray-900 
            text-gray-50 
            text-xs
            font-medium
            rounded-md 
            shadow-md
            pointer-events-none 
            ${className}
          `}
          {...props}
        >
          {children}
          <div 
            className={`absolute ${arrows[side].placement} ${arrows[side].alignment[align]} ${arrows[side].className}`} 
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
});

TooltipContent.displayName = 'TooltipContent';

export { TooltipProvider, TooltipRoot as Tooltip, TooltipTrigger, TooltipContent };