import { BUSINESS_TYPES } from '../supabaseClient';
import { matchPath } from 'react-router-dom';

export const ROUTE_ACCESS = {
  [BUSINESS_TYPES.CLEANING]: [
    '/home',
    //'/activity/contacts',
    //'/activity/contacts/:id',
    '/activity/calls',
    '/activity/calls/:id',
    '/activity/bookings',
    '/activity/tasks',
    '/business/faqs',
    '/business/services',
    '/caller/integrations',
    '/caller/voice',
    '/caller/workflows',
    '/caller/notifications',
    '/reports/insights',
    '/account/general',
    '/account/payment'
  ],
  [BUSINESS_TYPES.LAUNDROMAT]: [
    '/home',
    //'/activity/contacts',
    //'/activity/contacts/:id',
    '/activity/calls',
    '/activity/calls/:id',
    '/activity/tasks',
    '/business/knowledge',
    '/business/triggers',
    '/caller/voice',
    '/caller/notifications',
    '/reports/insights',
    '/account/general',
    '/account/payment',
    '/onboarding/business'
  ]
};

// Helper function to check if a route is accessible
export const isRouteAccessible = (businessType, pathname) => {
  if (!businessType || !pathname) return false;

  const allowedRoutes = ROUTE_ACCESS[businessType] || [];

  return allowedRoutes.some((route) => {
    // Create a match pattern object
    const pattern = {
      path: route,
      caseSensitive: false,
      end: true
    };

    // Use matchPath with proper parameters
    return matchPath(pattern, pathname);
  });
};