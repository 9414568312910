import { createClient } from "@supabase/supabase-js";

// Configuration constants
export const SUPABASE_CONFIG = {
  CLEANING: {
    URL: "https://uubpymktlfrdiiphjobe.supabase.co",
    ANON_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV1YnB5bWt0bGZyZGlpcGhqb2JlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc0NDYzNDksImV4cCI6MjA0MzAyMjM0OX0.r5EPjD8htgP3MkmM6n0eUNbuJT6yIAqtnwYb_eIhJLY",
    SERVICE_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV1YnB5bWt0bGZyZGlpcGhqb2JlIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyNzQ0NjM0OSwiZXhwIjoyMDQzMDIyMzQ5fQ.DC94ceFqCiqwUkxoZCv-RDTOdBVQLrQewOogjRh8gwA"
  },
  LAUNDROMAT: {
    URL: "https://mbppphoddpitkedffmao.supabase.co",
    ANON_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1icHBwaG9kZHBpdGtlZGZmbWFvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA5MDk1MTQsImV4cCI6MjA0NjQ4NTUxNH0.8e1ugDoUBryeoMGKgV0AVRuYnNq1AKSplXUL-qCys5Q",
    SERVICE_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1icHBwaG9kZHBpdGtlZGZmbWFvIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMDkwOTUxNCwiZXhwIjoyMDQ2NDg1NTE0fQ._UezhGRf2hbZPOYLocSzE5ghznae7WZhEtfnXCBlcbU"
  }
};

export const BUSINESS_TYPES = {
  CLEANING: 'cleaning',
  LAUNDROMAT: 'laundromat'
};

// Create clients with their own storage namespace
// Add these options to your existing client creations
const clientOptions = {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
  global: {
    headers: {
      'Accept': 'application/json'
    }
  }
};

// Update just the client creations
const cleaningClient = createClient(
  SUPABASE_CONFIG.CLEANING.URL,
  SUPABASE_CONFIG.CLEANING.ANON_KEY,
  {
    ...clientOptions,
    auth: {
      ...clientOptions.auth,
      storageKey: 'cleaning-auth-token'
    }
  }
);

const laundromatClient = createClient(
  SUPABASE_CONFIG.LAUNDROMAT.URL,
  SUPABASE_CONFIG.LAUNDROMAT.ANON_KEY,
  {
    ...clientOptions,
    auth: {
      ...clientOptions.auth,
      storageKey: 'laundromat-auth-token'
    }
  }
);

let currentClient = cleaningClient;
let currentBusinessType = BUSINESS_TYPES.CLEANING;

export const getSupabaseClient = (businessType) => {
  const type = businessType?.toLowerCase();
  // Update the current client if business type changes
  if (type && type !== currentBusinessType) {
    currentBusinessType = type;
    currentClient = type === BUSINESS_TYPES.LAUNDROMAT ? laundromatClient : cleaningClient;
  }
  return currentClient;
};

// For backwards compatibility, export a default client
export const supabase = new Proxy({}, {
  get: function(target, prop) {
    // Forward all operations to the current client
    return currentClient[prop];
  }
});